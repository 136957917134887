import { BaseWidgetClass } from './base-widget-models/base-widget-class';
import { RandomQuestionWidgetData } from '@apiModels';
import { TypedResponse } from './base-widget-models/typed-widget-response';

export interface RealTimeRandomQuestionForm {
    questionbankId?: number;
}

export interface DetailRandomQuestionForm {
    realTimeRandomQuestionFormData: RealTimeRandomQuestionForm;
    attempts?: number;
    enableAnswerExplanation?: boolean;
    enableCustomExplanation?: boolean;
    enableGeneralExplanation?: boolean;
    fulfillmentAvailable?: boolean;
    fulfillment?: 'NONE' | 'SHOW' | 'QUESTION_CORRECTLY_ANSWERED' | 'QUESTION_ANSWERED';
}

export interface RandomQuestionWidgetDataSpec extends RandomQuestionWidgetData {
    fulfillment: 'NONE' | 'SHOW' | 'QUESTION_CORRECTLY_ANSWERED' | 'QUESTION_ANSWERED';
}

export class RandomQuestionWidgetClass extends BaseWidgetClass<RandomQuestionWidgetDataSpec> {
    constructor(data: TypedResponse<RandomQuestionWidgetDataSpec>) {
        super(data);
    }

    getRealTimeFormData(): RealTimeRandomQuestionForm {
        return {
            questionbankId: this.data?.questionbankId ?? null,
        };
    }

    getDetailFormData(): DetailRandomQuestionForm {
        return {
            realTimeRandomQuestionFormData: this.getRealTimeFormData(),
            attempts: this.data?.attempts ?? 0,
            enableAnswerExplanation: this.data?.enableAnswerExplanation ?? null,
            enableCustomExplanation: this.data?.enableCustomExplanation ?? null,
            enableGeneralExplanation: this.data?.enableGeneralExplanation ?? null,
            fulfillmentAvailable: this.data.fulfillmentAvailable ?? null,
            fulfillment: this.data.fulfillment ?? 'NONE',
        };
    }
}
