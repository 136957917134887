import { QuestionWidgetData } from '@apiModels';
import { IFileUploadElement } from '@shared/models/file-upload.model';
import { RealTimeBaseQuestionWidgetForm } from './question-base-model';

export function InitRealTimeQuestionFormData(
    data: QuestionWidgetData,
    fileUploadElement: IFileUploadElement,
    mediaUploadElement: IFileUploadElement,
    videoUploadElement: IFileUploadElement,
    audioUploadElement: IFileUploadElement,
    videoSubtitleElement: IFileUploadElement
): RealTimeBaseQuestionWidgetForm {
    const { question, attempts } = data;
    return {
        answers: [],
        text: question?.text ?? null,
        introduction: question?.introduction ?? null,
        hasAnswerMedia: question?.hasAnswerMedia ?? false,
        hasMedia: question?.hasMedia ?? false,
        hasPdf: question?.hasPdf ?? false,
        hasVideo: question?.hasVideo ?? false,
        hasAudio: question?.hasAudio ?? false,
        attempts: attempts ?? 0,
        pdfResourceElement: {
            fileItem: fileUploadElement,
            elementId: question?.pdfResourceId ?? null,
        },
        mediaResourceElement: {
            fileItem: mediaUploadElement,
            elementId: question?.mediaResourceId ?? null,
        },
        videoResourceElement: {
            fileItem: videoUploadElement,
            elementId: question?.videoResourceId ?? null,
        },
        audioResourceElement: {
            fileItem: audioUploadElement,
            elementId: question?.audioResourceId ?? null,
        },
        videoSubtitleResourceElement: {
            element: {
                elementId: question?.videoSubtitle?.subtitleResourceId ?? null,
                fileItem: videoSubtitleElement,
            },
            originalFileName: question?.videoSubtitle?.originalFileName ?? null,
            srcLang: question?.videoSubtitle?.srcLang ?? null,
        },
        videoTranscript: question?.videoTranscript ?? null,
        audioDescription: question?.audioDescription ?? null,
    };
}
