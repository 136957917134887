<form [formGroup]="form" data-e2e="group-question">
    <app-question-real-time-widget-wrapper
        *ngIf="!testEnv"
        [title]="'QUESTION-WIDGET.COMMON.GROUP-QUESTION'"
        [config]="config"
        [form]="form"
        [editorLocation]="editorLocation"
        [hasMediaInfo]="hasMedia"
        [hasPdfInfo]="hasPdf"
        [hasVideoInfo]="hasVideo"
        [hasAudioInfo]="hasAudio"
        [attempts]="attempts"
        data-e2e="group-question"
    >
        <ul class="groups" formArrayName="groups">
            <li
                class="groups__elem"
                *ngFor="let group of groups.controls; let i = index; let len = count"
                [formGroupName]="i"
            >
                <header class="groups__header">
                    <input
                        class="groups__input"
                        formControlName="name"
                        (keydown)="$event.key === 'Enter' ? $event.preventDefault() : null"
                        [placeholder]="'QUESTION-WIDGET.COMMON.GROUP-TITLE-PLACEHOLDER' | translate "
                    />
                    <button
                        (click)="groupsOpened[i] = !groupsOpened[i]"
                        [class.card-not-opened]="!groupsOpened[i]"
                        class="collapse-icon template-btn icon-only ghost height-small icon-only radius-25"
                        type="button"
                    >
                        <span class="nitroicon-arrow_right editor-btn-icon"></span>
                    </button>
                    <button
                        *ngIf="groups.length > 2"
                        class="editor-btn danger ghost icon-only height-small groups__delete-btn"
                        (click)="removeGroup(i)"
                    >
                        <span class="nitroicon-delete editor-btn-icon"></span>
                    </button>
                </header>
                <div
                    class="groups__wrapper"
                    *ngIf="groupsOpened[i]"
                    [@cardCollapse]="{ value: true, params: { animTiming: '500ms ease' } }"
                >
                    <section class="groups__body-wrapper">
                        <ul
                            formArrayName="elements"
                            class="groups__body"
                            [sortablejs]="getGroup(i)"
                            [sortablejsOptions]="options"
                        >
                            <li
                                class="group-tag with-margin"
                                *ngFor="let element of getGroup(i).controls; let y = index; let len = count"
                            >
                                <button class="editor-btn ghost height-small icon-only card-drag-handler handle">
                                    <span class="nitroicon-move editor-btn-icon"></span>
                                </button>
                                <app-quill
                                    *ngIf="!testEnv"
                                    class="app-quill"
                                    [formControlName]="y"
                                    [toolbarVisible]="false"
                                    [placeholder]="'QUESTION-WIDGET.COMMON.GROUP-ELEM-PLACEHOLDER' | translate"
                                ></app-quill>

                                <button
                                    class="editor-btn danger ghost height-small icon-only group-tag__delete-btn"
                                    (click)="deleteElement(i, y)"
                                >
                                    <span class="nitroicon-delete editor-btn-icon"></span>
                                </button>
                            </li>
                        </ul>
                    </section>
                    <button
                        class="editor-btn primary ghost btn-w100 left-icon uppercase add-btn"
                        (click)="addElement(i)"
                    >
                        <span class="nitroicon-plussz editor-btn-icon"></span>
                        {{ 'QUESTION-WIDGET.COMMON.GROUP-ELEM' | translate }}
                    </button>
                </div>
            </li>

            <!-- Csoport hozzáadása ha 4 alatt van a csoportok száma   -->
            <li *ngIf="groups.length < 4" class="groups__elem">
                <button class="editor-btn primary ghost btn-w100 left-icon uppercase add-btn" (click)="addNewGroup()">
                    <span class="nitroicon-plussz editor-btn-icon"></span>
                    {{ 'QUESTION-WIDGET.COMMON.GROUP-GROUP' | translate }}
                </button>
            </li>
        </ul>
        <!-- Nem csoportosított elemek   -->

        <section class="groups__elem">
            <header class="groups__header">
                <p class="groups__title">{{ 'QUESTION-WIDGET.COMMON.GROUP-FALSE' | translate }}</p>
                <button
                    (click)="groupsOpened[4] = !groupsOpened[4]"
                    [class.card-not-opened]="!groupsOpened[4]"
                    class="collapse-icon template-btn icon-only ghost height-small icon-only radius-25"
                    type="button"
                >
                    <span class="nitroicon-arrow_right editor-btn-icon"></span>
                </button>
            </header>
            <div
                class="groups__wrapper"
                *ngIf="groupsOpened[4]"
                [@cardCollapse]="{ value: true, params: { animTiming: '500ms ease' } }"
            >
                <ul
                    class="nonGrouped"
                    formArrayName="nonGrouped"
                    [sortablejs]="nonGrouped"
                    [sortablejsOptions]="options"
                >
                    <li class="group-tag" *ngFor="let item of nonGrouped.controls; let i = index; let len = count">
                        <button class="editor-btn ghost height-small icon-only card-drag-handler handle">
                            <span class="nitroicon-move editor-btn-icon"></span>
                        </button>
                        <app-quill
                            *ngIf="!testEnv"
                            class="app-quill"
                            [formControlName]="i"
                            [toolbarVisible]="false"
                            [placeholder]="'QUESTION-WIDGET.COMMON.GROUP-ELEM-PLACEHOLDER' | translate"
                        ></app-quill>

                        <button
                            class="editor-btn danger ghost height-small icon-only group-tag__delete-btn"
                            (click)="removeFalseElem(i)"
                        >
                            <span class="nitroicon-delete editor-btn-icon"></span>
                        </button>
                    </li>
                </ul>
                <button
                    class="editor-btn primary ghost btn-w100 left-icon uppercase add-btn"
                    (click)="addNewFalseElem()"
                >
                    <span class="nitroicon-plussz editor-btn-icon"></span>
                    {{ 'QUESTION-WIDGET.COMMON.GROUP-FALSE' | translate }}
                </button>
            </div>
        </section>
    </app-question-real-time-widget-wrapper>
</form>
