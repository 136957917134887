export const initialAudioResource = {
    description: null,
    resourceId: null,
    audioResourceElement: null,
    resourceIds: [],
    resourceType: null,
};

export const initialVideoResource = {
    hasTranscript: null,
    previewImageResourceId: null,
    resourceIds: [],
    resourceType: null,
    videoAutoplay: null,
    videoControlsEnabled: true,
    videoAvailableScales: [],
    videoExpectedPosition: null,
    videoLoop: null,
    videoMuted: null,
    videoPlayAsGif: null,
    videoResourceId: null,
    videoResourceElement: null,
    videoSubtitle: {
        element: {
            elementId: null,
            fileItem: null,
        },
        srcLang: null,
        originalFileName: null,
    },
    videoTranscript: null,
};
