<div *ngIf="!break && userProfile">
    <header class="main-header">
        <div class="left-wrapper">
            <div
                class="main-header__logo-icon"
                [class.with-content]="shouldShowBackArrowIcon"
                [class.not-show]="!this.shouldShowCourseListIcon && !shouldShowBackArrowIcon"
            >
                <div id="exported-template-container__back" [class.with-content]="shouldShowBackArrowIcon"></div>
                <a
                    class="editor-btn primary border-0 icon-only height-small radius-25 main-header__logo"
                    *ngIf="!shouldShowBackArrowIcon && !hasExamAdminRole"
                    [routerLink]="['/courses-list']"
                >
                    <span class="nitroicon-courses"></span>
                </a>
            </div>
        </div>
        <app-application-selector class="app-application-selector" *ngIf="hasExamAdminRole"></app-application-selector>
        <a *ngIf="!hasExamAdminRole" class="main-header__logo" [routerLink]="['/courses-list']">
            <img src="./assets/images/nitro_learning_logo_courze_cloud_black.svg" alt="" />
        </a>
        <div id="exported-template-container__center"></div>
        <div id="exported-template-container"></div>
        <div id="exported-template-container__menu"></div>
        <button type="button" class="editor-btn height-small radius-25 border-0 primary icon-only" (click)="navigateToQuestionBankPage()">
            <span class="nitroicon-single_question" [attr.aria-label]="'QUESTION_BANK.PAGE_TITLE' | translate"> </span>
        </button>
        <app-material-menu
            class="app-material-menu"
            [menuList]="userMmenuItems"
            [icon]="'nitroicon-profile'"
            (actionEvent)="onHeaderAction($event)"
        ></app-material-menu>
    </header>
    <main class="main">
        <router-outlet #router="outlet"></router-outlet>
    </main>
    <app-footer></app-footer>
</div>

<div *ngIf="break">
    <h1>Nem támogatott méret</h1>
</div>
