<form [formGroup]="form" class="document-widget">
    <app-document-upload
        *ngIf="editorLocation === 'detail' || !hasElement"
        class="app-document-upload"
        [formControlName]="'element'"
        [courseId]="config?.courseId"
        [questionBankQuestion]="config?.questionBankId && config?.widgetId ? { questionBankId: config.questionBankId, questionId: config.widgetId } : null"
        [originalFileName]="config?.originalFileName"
    >
    </app-document-upload>

    <div class="document-widget__description" *ngIf="editorLocation === 'detail'">
        <ng-content></ng-content>
    </div>

    <div class="document-download-wrapper__hidden-span" aria-hidden="true" #placeholderText>
        {{'DOCUMENT-WIDGET.DOCUMENT-TITLE-PLACEHOLDER' | translate}}
    </div>
    <div
        class="document-download-wrapper"
        tabindex="0"
        [ngClass]="align"
        *ngIf="editorLocation === 'detail' || hasElement"
    >
        <div class="align-selector" [ngClass]="editorLocation">
            <button
                (click)="updateAlign('LEFT')"
                class="align-selector__btn editor-btn section-btn height-medium"
                [ngClass]="{ selected: align === 'LEFT' }"
            >
                <span class="nitroicon-text_widget_format_align_left"></span>
            </button>
            <button
                (click)="updateAlign('CENTER')"
                class="align-selector__btn editor-btn section-btn height-medium"
                [ngClass]="{ selected: align === 'CENTER' }"
            >
                <span class="nitroicon-text_widget_format_align_center"></span>
            </button>
            <button
                (click)="updateAlign('RIGHT')"
                class="align-selector__btn editor-btn section-btn height-medium"
                [ngClass]="{ selected: align === 'RIGHT' }"
            >
                <span class="nitroicon-text_widget_format_align_right"></span>
            </button>
        </div>
        <button class="template-btn section-btn left-icon document-download-wrapper__button">
            <span #downloadIcon class="nitroicon-download btn-icon"></span>
            <input
                [ngStyle]="{ 'width.px': width }"
                (input)="resize()"
                class="generic-input-reset"
                type="text"
                [placeholder]="'DOCUMENT-WIDGET.DOCUMENT-TITLE-PLACEHOLDER' | translate"
                [formControlName]="'title'"
            />
            <span #hiddenText class="document-download-wrapper__hidden-span" aria-hidden="true"
                >{{ this.form.controls['title'].value }}</span
            >
        </button>
    </div>
</form>
