import {
    GeneralAudioExplanation,
    GeneralTextExplanation,
    ResultWithAudioExplanation,
    ResultWithTextExplanation,
} from '@apiModels';
import { IGeneralVideoExplanation } from 'src/app/api-wrapper/models/general-video-explanation-wrapper.interface';
import { IResultWithVideoExplanation } from 'src/app/api-wrapper/models/result-video-explanation-wrapper.interface';
import { QuestionWidgetData } from 'src/app/api/models/question-widget-data';
import { ISubtitleFormData } from '../video-widget-model';
import { ExplanationResourceType } from './../../explanation-resource.type';
import {
    InitGeneralExplanationConfig,
    InitGeneralExplanationType,
    InitResultExplanationConfig,
    InitResultExplanationType,
} from './init-question-detail-explanation-form';

export interface DetailQuestionWidgetForm {
    attempts: number;
    enableAnswerExplanation: boolean;
    enableCustomExplanation: boolean;
    enableGeneralExplanation: boolean;
    fulfillmentAvailable: boolean;
    fulfillment: 'NONE' | 'SHOW' | 'QUESTION-ANSWERED' | 'QUESTION_CORRECTLY_ANSWERED' | any;
    explanation?: string; //  DEPRECATED
    explanationCorrect?: string; //  DEPRECATED
    explanationIncorrect?: string; //  DEPRECATED
    explanationPartialCorrect?: string; //  DEPRECATED
    hasAnswerExplanation?: boolean;
    hasAnswerMedia?: boolean;
    hasCustomExplanation?: boolean;
    hasExplanation?: boolean; //  DEPRECATED
    hasMedia?: boolean;
    hasVideo?: boolean;
    hasAudio?: boolean;
    hasPdf?: boolean;
    pdfOriginalFileName?: string;
    questionType: string;
    mediaDescription?: string;
    pdfDescription?: string;
    videoTranscript?: string;
    videoSubtitle?: ISubtitleFormData;
    audioDescription?: string;
    generalExplanationType?: ExplanationResourceType;
    resultExplanationType?: ExplanationResourceType;
    generalExplanationConfig?: GeneralAudioExplanation | GeneralTextExplanation | IGeneralVideoExplanation;
    resultExplanationConfig?: ResultWithAudioExplanation | ResultWithTextExplanation | IResultWithVideoExplanation;
}

export function InitDetailQuestionFormData(data: QuestionWidgetData): DetailQuestionWidgetForm {
    const {
        question,
        attempts,
        enableAnswerExplanation,
        enableCustomExplanation,
        enableGeneralExplanation,
        fulfillmentAvailable,
        fulfillment,
    } = data;
    return {
        attempts: attempts ?? null,
        enableAnswerExplanation: enableAnswerExplanation ?? null,
        enableCustomExplanation: enableCustomExplanation ?? null,
        enableGeneralExplanation: enableGeneralExplanation ?? null,
        fulfillmentAvailable: fulfillmentAvailable ?? false,
        fulfillment: fulfillment ?? 'NONE',
        explanation: question?.explanation ?? null, // DEPRECATED
        explanationCorrect: question?.explanationCorrect ?? null, // DEPRECATED
        explanationIncorrect: question?.explanationIncorrect ?? null, // DEPRECATED
        explanationPartialCorrect: question?.explanationPartialCorrect ?? null, // DEPRECATED
        hasAnswerExplanation: question?.hasAnswerExplanation ?? false,
        hasAnswerMedia: question?.hasAnswerMedia ?? false,
        hasCustomExplanation: question?.hasCustomExplanation ?? false,
        hasExplanation: question?.hasExplanation ?? false, // DEPRECATED
        hasMedia: question?.hasMedia ?? false,
        hasPdf: question?.hasPdf ?? false,
        hasVideo: question?.hasVideo ?? false,
        hasAudio: question?.hasAudio ?? false,
        pdfOriginalFileName: question?.pdfOriginalFileName ?? null,
        questionType: question?.questionType ?? null,
        mediaDescription: question?.mediaDescription ?? null,
        pdfDescription: question?.pdfDescription ?? null,
        videoTranscript: question?.videoTranscript ?? null,
        videoSubtitle: {
            element: {
                elementId: question?.videoSubtitle?.subtitleResourceId ?? null,
                fileItem: null,
            },
            originalFileName: question?.videoSubtitle?.originalFileName ?? null,
            srcLang: question?.videoSubtitle?.srcLang ?? null,
        },
        audioDescription: question?.audioDescription ?? null,
        generalExplanationType: InitGeneralExplanationType(question),
        resultExplanationType: InitResultExplanationType(question),
        generalExplanationConfig: InitGeneralExplanationConfig(question),
        resultExplanationConfig: InitResultExplanationConfig(question),
    };
}
