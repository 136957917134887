<form [formGroup]="form">
    <header class="detail-form__header" [ngClass]="sizeClass">
        <div class="detail-form__header__action-buttons">
            <button
                class="editor-btn height-small icon-only primary"
                [ngClass]="{ accent: size === widgetSizeEnum.MARGIN }"
                type="button"
                (click)="setWidgetSize(widgetSizeEnum.MARGIN)"
            >
                <span class="nitroicon-block_narrow editor-btn-icon"></span>
            </button>
            <button
                class="editor-btn height-small icon-only primary"
                [ngClass]="{ accent: size === widgetSizeEnum.NOMARGIN }"
                type="button"
                (click)="setWidgetSize(widgetSizeEnum.NOMARGIN)"
            >
                <span class="nitroicon-block_mid editor-btn-icon"></span>
            </button>
            <button
                class="editor-btn height-small icon-only primary"
                [ngClass]="{ accent: size === widgetSizeEnum.FULLSIZE }"
                type="button"
                (click)="setWidgetSize(widgetSizeEnum.FULLSIZE)"
            >
                <span class="nitroicon-block_fullsize editor-btn-icon"></span>
            </button>
        </div>
    </header>

    <ng-template #realTimeElement>
        <app-document-upload
            class="app-document-upload"
            [formControlName]="'element'"
            [courseId]="config?.courseId"
            [questionBankQuestion]="config?.questionBankId && config?.widgetId ? { questionBankId: config.questionBankId, questionId: config.widgetId } : null"
            [height]="height"
            [acceptTypes]="'application/pdf'"
            [regExp]="regExp"
        >
            <div class="iframe-empty-state">
                <img src="assets/images/pdf_embed_empty-state.svg" class="embedded-document-widget__img" alt="" />
                <span class="embedded-document-widget__upload-text"
                    >{{ 'DOCUMENT-WIDGET.DOCUMENT-DRAG-OR' | translate }}</span
                >
                <button type="button" class="embedded-document-widget__upload-button editor-btn section-btn border-2">
                    {{ 'DOCUMENT-WIDGET.DOCUMENT-UPLOAD' | translate }}
                </button>
            </div>
        </app-document-upload>
    </ng-template>
    <ng-container *ngIf="size === widgetSizeEnum.FULLSIZE">
        <ng-container *ngTemplateOutlet="realTimeElement"></ng-container>
    </ng-container>

    <section class="detail-form resizable" [ngClass]="sizeClass">
        <div class="detail-form__site-left">
            <ng-container *ngIf="size !== widgetSizeEnum.FULLSIZE">
                <ng-container *ngTemplateOutlet="realTimeElement"></ng-container>
            </ng-container>

            <app-collapse-card [title]="'IMAGE-WIDGET.ACCESSIBILTY-CARD' | translate" [opened]="true">
                <div class="detail-form__group">
                    <label class="detail-form__group__label" for="description"
                        >{{ 'EMBEDED-WIDGET.ACCESSIBILTY' | translate }}
                    </label>
                    <textarea name="description" id="description" formControlName="description"></textarea>
                </div>
            </app-collapse-card>
        </div>

        <div class="detail-form__site-right">
            <app-collapse-card [title]="'EMBEDED-WIDGET.SETTINGS' | translate" [opened]="true">
                <fieldset class="detail-form__group">
                    <label class="detail-form__group__label" for="title">
                        {{ 'EMBEDED-WIDGET.TITLE' | translate }}
                    </label>
                    <input
                        name="title"
                        class="detail-form__input"
                        id="title"
                        [placeholder]="'EMBEDED-WIDGET.TITLE-PLACEHOLDER' | translate"
                        formControlName="title"
                    />
                </fieldset>
                <fieldset class="detail-form__group">
                    <legend class="detail-form__group__label">{{ 'EMBEDED-WIDGET.HEIGHT' | translate }}</legend>
                    <div class="detail-form__number-block">
                        <app-number-input formControlName="height" [min]="1" [max]="2000"></app-number-input>
                    </div>
                </fieldset>
            </app-collapse-card>
            <app-collapse-card [title]="'IMAGE-WIDGET.MEASUREMENT-CARD' | translate" [opened]="true">
                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'EMBEDED-WIDGET.FULLFILMENT' | translate"
                    [options]="fulfillmentAvailableOptions"
                    formControlName="fulfillmentAvailable"
                ></app-radio-group-control>

                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'FORM-CONTROLS.FULFILLMENT-TITLE' | translate"
                    [options]="fulfillmentOptions"
                    formControlName="fulfillment"
                ></app-radio-group-control>
            </app-collapse-card>
        </div>
    </section>
</form>
