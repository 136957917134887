<form [formGroup]="form">
    <header class="detail-form__header" [ngClass]="sizeClass">
        <section class="header__video-actions" [ngClass]="sizeClass">
            <div class="detail-form__header__action-buttons">
                <button class="editor-btn height-small left-icon primary new-card-btn" (click)="uploadVideo()">
                    <span class="nitroicon-upload editor-btn-icon"></span>
                    {{ (hasVideo ? 'VIDEO-WIDGET.FILE-CHANGE' : 'VIDEO-WIDGET.FILE-UPLOAD') | translate }}
                </button>
                <button
                    class="editor-btn height-small icon-only primary"
                    [ngClass]="{ accent: size === widgetSizeEnum.MARGIN }"
                    type="button"
                    (click)="setWidgetSize(widgetSizeEnum.MARGIN)"
                >
                    <span class="nitroicon-block_narrow editor-btn-icon"></span>
                </button>
                <button
                    class="editor-btn height-small icon-only primary"
                    [ngClass]="{ accent: size === widgetSizeEnum.NOMARGIN }"
                    type="button"
                    (click)="setWidgetSize(widgetSizeEnum.NOMARGIN)"
                >
                    <span class="nitroicon-block_mid editor-btn-icon"></span>
                </button>
                <button
                    class="editor-btn height-small icon-only primary"
                    [ngClass]="{ accent: size === widgetSizeEnum.FULLSIZE }"
                    type="button"
                    (click)="setWidgetSize(widgetSizeEnum.FULLSIZE)"
                >
                    <span class="nitroicon-block_fullsize editor-btn-icon"></span>
                </button>
            </div>

            <div class="detail-form__header__action-buttons-wrapper">
                <button class="editor-btn height-small left-icon primary new-card-btn" (click)="uploadPreviewImage()">
                    <span class="nitroicon-image editor-btn-icon"></span>
                    {{ (hasPreviewImage ? 'VIDEO-WIDGET.PREVIEW-IMAGE-CHANGE' : 'VIDEO-WIDGET.PREVIEW-IMAGE-UPLOAD') |
                    translate }}
                </button>
                <button
                    *ngIf="hasPreviewImage"
                    class="editor-btn height-small icon-only danger"
                    type="button"
                    (click)="deletePreviewImage()"
                >
                    <span class="nitroicon-delete editor-btn-icon"></span>
                </button>
            </div>
        </section>
    </header>

    <ng-template #realTimeElement>
        <div class="video-text-widget" [ngClass]="[dividerLocationValue, setStarterImageSize()]">
            <section
                class="column left-column"
                [class.right-borders]="!flippedValue"
                [class.left-borders]="flippedValue"
                [style.order]="flippedValue ? 1 : 0"
            >
                <app-quill
                    *ngIf="!testEnv"
                    [formControlName]="'text'"
                    class="app-quill"
                    [toolbarVisible]="true"
                    [placeholder]="'IMAGE-TEXT-WIDGET.TEXT-SIDE-PLACEHOLDER' | translate"
                ></app-quill>
            </section>

            <section
                class="column right-column"
                [class.right-borders]="flippedValue"
                [class.left-borders]="!flippedValue"
                [ngClass]="videoFitValue"
                [class.fullsize]="widgetSizeEnum.FULLSIZE === config.starterWidgetSize"
            >
                <app-video-upload
                    #videoUploadControl
                    class="app-video-upload"
                    [showDeleteButtonOnHover]="false"
                    [formControlName]="'element'"
                    [posterValue]="previewImage"
                    [courseId]="config?.courseId"
                    [questionBankQuestion]="config?.questionBankId && config?.widgetId ? { questionBankId: config.questionBankId, questionId: config.widgetId } : null"
                    [subtitle]="subtitle"
                    [loop]="loop"
                    [muted]="muted"
                    [fullSize]="size === widgetSizeEnum.FULLSIZE"
                    [textWidget]="true"
                    [autoplay]="autoplay"
                    [controlsEnabled]="controlsEnabled"
                    [availableScales]="config?.availableScales ?? []"
                    (deletePoster)="deletePreviewImage()"
                >
                    <div class="video-text-widget__upload">
                        <img src="assets/images/simple-video-widget.svg" class="video-text-widget__img" alt="" />
                    </div>
                </app-video-upload>
                <app-image-upload
                    #previewImageControl
                    class="visually-hidden"
                    tabindex="-1"
                    [formControlName]="'previewImageElement'"
                    [courseId]="config?.courseId"
                >
                </app-image-upload>
            </section>
        </div>
    </ng-template>
    <ng-container *ngIf="size === widgetSizeEnum.FULLSIZE">
        <ng-container *ngTemplateOutlet="realTimeElement"></ng-container>
    </ng-container>

    <section class="detail-form resizable" [ngClass]="sizeClass">
        <div class="detail-form__site-left">
            <ng-container *ngIf="size !== widgetSizeEnum.FULLSIZE">
                <ng-container *ngTemplateOutlet="realTimeElement"></ng-container>
            </ng-container>

            <app-collapse-card
                [title]="'VIDEO-WIDGET.TRANSCRIPT' | translate"
                [opened]="true"
                [@cardCollapse]="{ value: true, params: { animTiming: '500ms ease' } }"
                class="detail-form__group"
                *ngIf="hasTranscript"
            >
                <textarea
                    [formControlName]="'transcript'"
                    [placeholder]="'VIDEO-WIDGET.CAPTION-PLACEHOLDER' | translate"
                ></textarea>
            </app-collapse-card>

            <app-collapse-card [title]="'VIDEO-WIDGET.SUBTITLE' | translate" [opened]="true" class="detail-form__group">
                <div [formGroupName]="'subtitleClass'">
                    <app-subtitle-upload
                        [formControlName]="'element'"
                        [courseId]="config?.courseId"
                        [questionBankQuestion]="config?.questionBankId && config?.widgetId ? { questionBankId: config.questionBankId, questionId: config.widgetId } : null"
                        [originalFileName]="originalFileName"
                    >
                        <div class="subtitle-empty-state">
                            <app-info-box-with-actions
                                class="app-info-box-with-actions"
                                [actionButtons]="[]"
                                [infoText]="'SUBTITLE-WIDGET.ACCEPTED-TYPES-WARNING'"
                            >
                            </app-info-box-with-actions>
                            <button class="editor-btn height-small left-icon primary new-card-btn">
                                <span class="nitroicon-upload editor-btn-icon"></span>
                                {{ 'SUBTITLE-WIDGET.FILE-UPLOAD' | translate }}
                            </button>
                        </div>
                    </app-subtitle-upload>

                    <div
                        class="detail-form__group"
                        *ngIf="hasSubtitle"
                        [@cardCollapse]="{ value: true, params: { animTiming: '500ms ease' } }"
                    >
                        <div class="detail-form__group">
                            <label class="detail-form__group__label" for="srclang"
                                >{{ 'VIDEO-WIDGET.SUBTITLE-LANGUAGE' | translate }}
                            </label>
                            <app-select-input
                                id="srclang"
                                [formControlName]="'srcLang'"
                                [options]="langOptions"
                            ></app-select-input>
                        </div>
                    </div>
                </div>
            </app-collapse-card>
        </div>

        <div class="detail-form__site-right">
            <app-collapse-card [title]="'IMAGE-TEXT-WIDGET.LAYOUT-TITLE' | translate" [opened]="true">
                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'IMAGE-TEXT-WIDGET.DIVIDER-LOCATION-TITLE' | translate"
                    [options]="dividerOptions"
                    [layout]="'horizontal'"
                    formControlName="dividerLocation"
                ></app-radio-group-control>

                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'IMAGE-TEXT-WIDGET.POSITION-TITLE' | translate"
                    [options]="flippedAvailableOptions"
                    [layout]="'horizontal'"
                    formControlName="flipped"
                ></app-radio-group-control>

                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'IMAGE-TEXT-WIDGET.VIDEO-FIT' | translate"
                    [options]="imageFitOptions"
                    [layout]="'horizontal'"
                    formControlName="videoFit"
                ></app-radio-group-control>
            </app-collapse-card>
            <app-collapse-card [title]="'VIDEO-WIDGET.SETTINGS-CARD' | translate" [opened]="true">
                <fieldset class="detail-form__group">
                    <legend class="detail-form__group__label">{{ 'VIDEO-WIDGET.PLAY-MODE' | translate }}</legend>
                    <app-checkbox-control
                        class="detail-form__checkbox"
                        *ngFor="let play of playMode"
                        [id]="play.controlName"
                        [formControlName]="play.controlName"
                        [labelText]="play.label"
                    ></app-checkbox-control>
                </fieldset>
                <fieldset class="detail-form__group">
                    <legend class="detail-form__group__label">{{ 'VIDEO-WIDGET.DISPLAYABLE' | translate }}</legend>
                    <app-checkbox-control
                        class="detail-form__checkbox"
                        *ngFor="let display of displayable"
                        [id]="display.controlName"
                        [formControlName]="display.controlName"
                        [labelText]="display.label"
                    ></app-checkbox-control>
                </fieldset>
            </app-collapse-card>

            <app-collapse-card [title]="'IMAGE-WIDGET.MEASUREMENT-CARD' | translate" [opened]="true">
                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'IMAGE-TEXT-WIDGET.FULFILLMENT-AVAILABLE-TITLE' | translate"
                    [options]="fulfillmentAvailableOptions"
                    formControlName="fulfillmentAvailable"
                ></app-radio-group-control>

                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'FORM-CONTROLS.FULFILLMENT-TITLE' | translate"
                    [options]="fulfillmentOptions"
                    formControlName="fulfillment"
                ></app-radio-group-control>
            </app-collapse-card>
        </div>
    </section>
</form>
