<form
    data-e2e="video-text"
    [formGroup]="form"
    class="video-text-widget"
    [ngClass]="[dividerLocationValue, setStarterImageSize()]"
>
    <section
        class="column left-column"
        [class.right-borders]="!flippedValue"
        [class.left-borders]="flippedValue"
        [style.order]="flippedValue ? 1 : 0"
    >
        <app-quill
            *ngIf="!testEnv"
            [formControlName]="'text'"
            class="app-quill"
            [toolbarVisible]="true"
            [placeholder]="'IMAGE-TEXT-WIDGET.TEXT-SIDE-PLACEHOLDER' | translate"
        ></app-quill>

        <div
            class="real-time-actions"
            [class.right]="!flippedValue"
            [class.left]="flippedValue"
            (mousedown)="mouseOnButton = true"
            (mouseup)="mouseOnButton = false"
            (mouseleave)="mouseOnButton = false"
            (mousemove)="changeLayout($event)"
        >
            <button
                class="switch-button editor-btn height-small icon-only primary"
                type="button"
                (mousemove)="$event.stopPropagation()"
                (click)="switchSides()"
            >
                <span class="nitroicon-switch_sides editor-btn-icon"></span>
            </button>
        </div>
    </section>

    <section
        class="column right-column"
        [class.right-borders]="flippedValue"
        [class.left-borders]="!flippedValue"
        [ngClass]="videoFitValue"
        [class.fullsize]="widgetSizeEnum.FULLSIZE === config.starterWidgetSize"
    >
        <app-video-upload
            #videoUploadControl
            class="app-video-upload"
            [showDeleteButtonOnHover]="false"
            [posterValue]="previewImageElement"
            [formControlName]="'element'"
            [courseId]="config?.courseId"
            [questionBankQuestion]="config?.questionBankId && config?.widgetId ? { questionBankId: config.questionBankId, questionId: config.widgetId } : null"
            [subtitle]="subtitle"
            [loop]="loop"
            [textWidget]="true"
            [fullSize]="fullSize"
            [muted]="muted"
            [autoplay]="autoplay"
            [controlsEnabled]="controlsEnabled"
            (deletePoster)="deletePreviewImage()"
            [availableScales]="config?.availableScales ?? []"
        >
            <div class="video-text-widget__upload">
                <img src="assets/images/simple-video-widget.svg" class="video-text-widget__img" alt="" />
            </div>
        </app-video-upload>
        <div class="custom-collapse-card">
            <app-collapse-card
                *ngIf="transcript && hasTranscript"
                [title]="'VIDEO-WIDGET.CAPTION-PLACEHOLDER' | translate"
                [opened]="false"
            >
                <figcaption class="video-widget__caption section-font-color" [innerHTML]="transcript"></figcaption>
            </app-collapse-card>
        </div>
    </section>
</form>
