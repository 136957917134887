<form [formGroup]="form">
    <app-question-detail-widget-wrapper [form]="form" [config]="config" [hasExplanationPerAnswer]="false">
        <app-matching-question-real-time-widget
            *ngIf="!testEnv"
            class="detail-form__real-time-widget"
            [formControlName]="'realTimeQuestionWidgetFormData'"
            [config]="config"
            [data]="form.get('realTimeQuestionWidgetFormData').value"
            [editorLocation]="'detail'"
            [hasAnswerMediaInfo]="hasAnswerMedia"
            [hasAnswerExplanation]="hasAnswerExplanation"
            [hasMediaInfo]="hasMedia"
            [hasPdfInfo]="hasPdf"
            [hasVideoInfo]="hasVideo"
            [hasAudioInfo]="hasAudio"
            [attemptsInfo]="attempts"
        >
        </app-matching-question-real-time-widget>
    </app-question-detail-widget-wrapper>
</form>
