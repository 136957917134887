<form [formGroup]="form" data-e2e="matching-question">
    <app-question-real-time-widget-wrapper
        *ngIf="!testEnv"
        [title]="'QUESTION-WIDGET.COMMON.MATCHING-QUESTION'"
        [config]="config"
        [form]="form"
        [editorLocation]="editorLocation"
        [hasMediaInfo]="hasMedia"
        [hasPdfInfo]="hasPdf"
        [hasVideoInfo]="hasVideo"
        [hasAudioInfo]="hasAudio"
        [attempts]="attempts"
    >
        <ul class="answer-container" formArrayName="answers" [ngClass]="{ hasImage: hasAnswerMedia }">
            <li
                class="answer-container__answer"
                *ngFor="let answer of answers.controls; let i = index; let len = count"
                [formGroupName]="i"
            >
                <app-matching-question-answer
                    [form]="answer"
                    [len]="len"
                    [editorLocation]="editorLocation"
                    [config]="config"
                    [hasAnswerMediaInfo]="hasAnswerMedia"
                    [hasAnswerExplanation]="false"
                    (deleteRequest)="removeAnswer(i)"
                >
                </app-matching-question-answer>
            </li>
        </ul>
        <button class="editor-btn primary ghost btn-w100 left-icon uppercase new-answer-btn" (click)="addNewAnswer()">
            <span class="nitroicon-plussz editor-btn-icon"></span>
            {{ 'QUESTION-WIDGET.COMMON.NEW-ANSWER' | translate }}
        </button>
    </app-question-real-time-widget-wrapper>
</form>
