/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
    GeneralAudioExplanation,
    GeneralTextExplanation,
    GeneralVideoExplanation,
    QuestionWidgetData,
    ResultWithAudioExplanation,
    ResultWithVideoExplanation,
    ResultWithTextExplanation,
    SingleChoiceQuestion,
    Subtitle,
} from '@apiModels';
import { IFileUploadElement, IFileUploadValue } from '@shared/models/file-upload.model';
import { Question } from 'src/app/api/models/question';

import { BaseWidgetClass } from '../base-widget-models/base-widget-class';
import { TypedResponse } from '../base-widget-models/typed-widget-response';
import { WidgetModifyParams } from '../base-widget-models/widget-modify-params';
import { DetailQuestionWidgetForm, InitDetailQuestionFormData } from './init-question-detail-form';
import { ISubtitleFormData } from '../video-widget-model';
import { IGeneralVideoExplanation } from 'src/app/api-wrapper/models/general-video-explanation-wrapper.interface';
import { IResultWithVideoExplanation } from 'src/app/api-wrapper/models/result-video-explanation-wrapper.interface';
import { ExplanationResourceType } from '../../explanation-resource.type';
import { IResultWithAudioExplanation } from '../../result-audio-explanation-wrapper.interface';
import { IGeneralAudioExplanation } from '../../general-audio-explanation-wrapper.interface';

export interface RealTimeBaseQuestionWidgetForm {
    answers: any[];
    text: string;
    introduction: string;
    pdfResourceElement?: IFileUploadValue;
    mediaResourceElement?: IFileUploadValue;
    videoResourceElement?: IFileUploadValue;
    audioResourceElement?: IFileUploadValue;
    hasAnswerMedia?: boolean;
    hasMedia?: boolean;
    hasPdf?: boolean;
    hasVideo?: boolean;
    hasAudio?: boolean;
    attempts?: number;
    videoSubtitleResourceElement?: ISubtitleFormData;
    videoTranscript?: string;
    audioDescription?: string;
}

export interface DetailBaseQuestionWidgetForm extends DetailQuestionWidgetForm {
    realTimeQuestionWidgetFormData: RealTimeBaseQuestionWidgetForm;
}

export type MixedQuestionForm = RealTimeBaseQuestionWidgetForm & DetailBaseQuestionWidgetForm;
export interface QuestionModel extends Question {
    answers?: any[];
}
export interface BaseQuestionWidgetDataSpec extends QuestionWidgetData {
    question: QuestionModel;
    fulfillment: 'NONE' | 'SHOW' | 'QUESTION_CORRECTLY_ANSWERED' | 'QUESTION_ANSWERED';
    answers: any[];
}

export abstract class QuestionBaseWidgetClass<
    T extends BaseQuestionWidgetDataSpec,
    RealTimeForm extends RealTimeBaseQuestionWidgetForm,
    DetailForm extends DetailBaseQuestionWidgetForm
> extends BaseWidgetClass<T> {
    mediaUploadElement: IFileUploadElement | null = null;
    videoUploadElement: IFileUploadElement | null = null;
    audioUploadElement: IFileUploadElement | null = null;
    videoSubtitleElement: IFileUploadElement | null = null;
    constructor(data: TypedResponse<T>, answerModel: any) {
        super(data);
        if (answerModel) {
            this.data.answers = this.data?.question?.answers.map((a) => new answerModel(a));
        }
    }

    getRealTimeFormData(): RealTimeForm {
        return {
            answers: this.data.answers.map((b) => b.getFormData()),
            text: this.data?.question?.text ?? null,
            introduction: this.data?.question?.introduction ?? null,
            hasAnswerMedia: this.data?.question?.hasAnswerMedia ?? false,
            hasMedia: this.data?.question?.hasMedia ?? false,
            hasPdf: this.data?.question?.hasPdf ?? false,
            hasVideo: this.data?.question?.hasVideo ?? false,
            hasAudio: this.data?.question?.hasAudio ?? false,
            attempts: this.data?.attempts ?? 0,
            pdfResourceElement: {
                fileItem: this.fileUploadElement,
                elementId: this.data.question?.pdfResourceId ?? null,
            },
            mediaResourceElement: {
                fileItem: this.mediaUploadElement,
                elementId: this.data.question?.mediaResourceId ?? null,
            },
            videoResourceElement: {
                fileItem: this.videoUploadElement,
                elementId: this.data.question?.videoResourceId ?? null,
            },
            audioResourceElement: {
                fileItem: this.audioUploadElement,
                elementId: this.data.question?.audioResourceId ?? null,
            },
            videoSubtitleResourceElement: {
                element: {
                    elementId: this.data.question?.videoSubtitle?.subtitleResourceId ?? null,
                    fileItem: this.videoSubtitleElement,
                },
                originalFileName: this.data.question?.videoSubtitle?.originalFileName ?? null,
                srcLang: this.data.question?.videoSubtitle?.srcLang ?? null,
            },
            videoTranscript: this.data?.question?.videoTranscript ?? null,
            audioDescription: this.data?.question?.audioDescription ?? null,
        } as RealTimeForm;
    }

    getDetailFormData(): DetailForm {
        return {
            realTimeQuestionWidgetFormData: this.getRealTimeFormData(),
            ...(InitDetailQuestionFormData(this.data) as DetailQuestionWidgetForm),
        } as unknown as DetailForm;
    }

    transformModifiedData(modifiedData: RealTimeForm | DetailForm): Partial<MixedQuestionForm> {
        const detailForm = modifiedData.hasOwnProperty('realTimeQuestionWidgetFormData');
        let mixedForm: Partial<MixedQuestionForm>;
        if (detailForm) {
            const {
                hasAnswerMedia,
                hasMedia,
                hasPdf,
                hasVideo,
                hasAudio,
                attempts,
                videoTranscript,
                videoSubtitleResourceElement,
                audioDescription,
                ...rest
            } = (modifiedData as DetailForm).realTimeQuestionWidgetFormData;
            mixedForm = {
                ...modifiedData,
                ...rest,
            };
        } else {
            mixedForm = { ...modifiedData };
        }
        return mixedForm;
    }

    updatePdf(
        questionData: Question,
        pdfResourceElement: IFileUploadValue
    ): { pdfResourceId: string; pdfOriginalFileName: string } {
        const shouldSaveFile = this.shouldSaveFile(pdfResourceElement?.fileItem, this.data?.question.pdfResourceId);
        if (questionData.hasPdf && shouldSaveFile) {
            this.filesToSave.push(pdfResourceElement.fileItem);
        }
        this.fileUploadElement = questionData.hasPdf ? pdfResourceElement.fileItem : null;
        const pdfResourceId = questionData.hasPdf ? this.extractUUID(pdfResourceElement) : null;
        const originalName = this.fileUploadElement
            ? this.fileUploadElement.name
            : this?.data?.question?.pdfOriginalFileName;
        const pdfOriginalFileName = pdfResourceId ? originalName : null;
        return { pdfResourceId, pdfOriginalFileName };
    }

    updateMedia(questionData: Question, mediaResourceElement: IFileUploadValue): string | null {
        const shouldSaveFile = this.shouldSaveFile(
            mediaResourceElement?.fileItem,
            this.data?.question?.mediaResourceId
        );
        if (questionData.hasMedia && shouldSaveFile) {
            this.filesToSave.push(mediaResourceElement.fileItem);
        }

        this.mediaUploadElement = questionData.hasMedia ? mediaResourceElement.fileItem : null;
        return questionData.hasMedia ? this.extractUUID(mediaResourceElement) : null;
    }

    updateVideo(questionData: Question, videoResourceElement: IFileUploadValue): string | null {
        const shouldSaveFile = this.shouldSaveFile(
            videoResourceElement?.fileItem,
            this.data?.question?.videoResourceId
        );
        if (questionData.hasVideo && shouldSaveFile) {
            this.filesToSave.push(videoResourceElement.fileItem);
        }

        this.videoUploadElement = questionData.hasVideo ? videoResourceElement.fileItem : null;
        return questionData.hasVideo ? this.extractUUID(videoResourceElement) : null;
    }

    updateExplanationVideo(videoResourceElement: IFileUploadValue, prevVideoElement: string): string | null {
        const shouldSaveFile = this.shouldSaveFile(videoResourceElement?.fileItem, prevVideoElement);
        if (shouldSaveFile) {
            this.filesToSave.push(videoResourceElement.fileItem);
        }

        return this.extractUUID(videoResourceElement);
    }

    updateExplanationVideoSubtitle(subtitle: ISubtitleFormData, prevVideoSubtitleElement: string): Subtitle | null {
        const { element, ...rest } = subtitle;
        const subtitleResourceId = this.extractUUID(element);
        const fileUploadElement = element.fileItem;
        let originalFileName = fileUploadElement?.name ?? rest.originalFileName;
        if (!subtitleResourceId) {
            originalFileName = null;
        }

        const shouldSaveFile = this.shouldSaveFile(fileUploadElement, prevVideoSubtitleElement);
        if (shouldSaveFile) {
            this.filesToSave.push(element.fileItem);
        }

        return { ...rest, subtitleResourceId, originalFileName };
    }

    updateExplanationAudio(audioResourceElement: IFileUploadValue, prevAudioElement: string): string | null {
        const shouldSaveFile = this.shouldSaveFile(audioResourceElement?.fileItem, prevAudioElement);
        if (shouldSaveFile) {
            this.filesToSave.push(audioResourceElement.fileItem);
        }

        return this.extractUUID(audioResourceElement);
    }

    updateAudio(questionData: Question, audioResourceElement: IFileUploadValue): string | null {
        const shouldSaveFile = this.shouldSaveFile(
            audioResourceElement?.fileItem,
            this.data?.question?.audioResourceId
        );
        if (questionData.hasAudio && shouldSaveFile) {
            this.filesToSave.push(audioResourceElement.fileItem);
        }

        this.audioUploadElement = questionData.hasAudio ? audioResourceElement.fileItem : null;
        return questionData.hasAudio ? this.extractUUID(audioResourceElement) : null;
    }

    updateSubtitle(questionData: Question, subtitle?: ISubtitleFormData): Subtitle | null {
        if (!subtitle) {
            return null;
        }
        const { element, ...rest } = subtitle;
        const subtitleResourceId = this.extractUUID(element);
        const fileUploadElement = element.fileItem;
        let originalFileName = fileUploadElement?.name ?? rest.originalFileName;
        if (!subtitleResourceId) {
            originalFileName = null;
        }

        const shouldSaveFile = this.shouldSaveFile(
            fileUploadElement,
            this.data?.question?.videoSubtitle?.subtitleResourceId
        );
        if (shouldSaveFile) {
            this.filesToSave.push(element.fileItem);
        }

        this.videoSubtitleElement = questionData.videoSubtitle?.subtitleResourceId ? element.fileItem : null;

        return { ...rest, subtitleResourceId, originalFileName };
    }

    updateGeneralExplanation(
        generalExplanationType: ExplanationResourceType | null,
        generalExplanationConfig: GeneralAudioExplanation | GeneralTextExplanation | IGeneralVideoExplanation | null
    ): GeneralAudioExplanation | GeneralTextExplanation | GeneralVideoExplanation | null {
        switch (generalExplanationType) {
            case 'TEXT': {
                return {
                    explanationType: 'GENERAL_TEXT',
                    explanationText: (generalExplanationConfig as GeneralTextExplanation)?.explanationText,
                };
            }
            case 'VIDEO': {
                const explanationConfig = generalExplanationConfig as IGeneralVideoExplanation;
                const videoResource = (this.data?.question?.generalExplanationConfig as GeneralVideoExplanation)
                    ?.videoResource;
                return {
                    explanationType: 'GENERAL_VIDEO',
                    videoResource: {
                        ...explanationConfig?.videoResource,
                        resourceType: 'VIDEO',
                        videoResourceId: explanationConfig?.videoResource?.videoResourceElement
                            ? this.updateExplanationVideo(
                                  explanationConfig?.videoResource?.videoResourceElement,
                                  videoResource?.videoResourceId
                              )
                            : null,
                        videoSubtitle: explanationConfig?.videoResource?.videoSubtitle
                            ? this.updateExplanationVideoSubtitle(
                                  explanationConfig?.videoResource?.videoSubtitle,
                                  videoResource?.videoSubtitle?.subtitleResourceId
                              )
                            : null,
                    },
                };
            }
            case 'AUDIO': {
                const audioResource = (generalExplanationConfig as IGeneralAudioExplanation)?.audioResource;
                return {
                    explanationType: 'GENERAL_AUDIO',
                    audioResource: {
                        ...audioResource,
                        resourceType: 'AUDIO',
                        resourceId: audioResource?.audioResourceElement
                            ? this.updateExplanationAudio(
                                  audioResource?.audioResourceElement,
                                  (this.data?.question?.generalExplanationConfig as GeneralAudioExplanation)
                                      ?.audioResource?.resourceId
                              )
                            : null,
                    },
                };
            }
            default: {
                return null;
            }
        }
    }

    updateResultExplanation(
        resultExplanationType: ExplanationResourceType | null,
        resultExplanationConfig:
            | ResultWithAudioExplanation
            | ResultWithTextExplanation
            | IResultWithVideoExplanation
            | null
    ): ResultWithAudioExplanation | ResultWithTextExplanation | ResultWithVideoExplanation | null {
        switch (resultExplanationType) {
            case 'TEXT': {
                const explanationConfig = resultExplanationConfig as ResultWithTextExplanation;
                return {
                    explanationType: 'RESULT_WITH_TEXT',
                    explanationCorrectText: explanationConfig?.explanationCorrectText ?? null,
                    explanationIncorrectText: explanationConfig?.explanationIncorrectText ?? null,
                    partialCorrectText: explanationConfig?.partialCorrectText ?? null,
                };
            }
            case 'VIDEO': {
                const explanationConfig = resultExplanationConfig as IResultWithVideoExplanation;
                const questionExplanationConfig = this.data?.question
                    ?.resultExplanationConfig as ResultWithVideoExplanation;
                return {
                    explanationType: 'RESULT_WITH_VIDEO',
                    correctVideoResource: {
                        ...explanationConfig?.correctVideoResource,
                        resourceType: 'VIDEO',
                        videoResourceId: explanationConfig?.correctVideoResource?.videoResourceElement
                            ? this.updateExplanationVideo(
                                  explanationConfig?.correctVideoResource?.videoResourceElement,
                                  questionExplanationConfig?.correctVideoResource?.videoResourceId
                              )
                            : null,
                        videoSubtitle: explanationConfig?.correctVideoResource?.videoSubtitle
                            ? this.updateExplanationVideoSubtitle(
                                  explanationConfig?.correctVideoResource?.videoSubtitle,
                                  questionExplanationConfig?.correctVideoResource?.videoSubtitle?.subtitleResourceId
                              )
                            : null,
                    },
                    incorrectVideoResource: {
                        ...explanationConfig?.incorrectVideoResource,
                        resourceType: 'VIDEO',
                        videoResourceId: explanationConfig?.incorrectVideoResource?.videoResourceElement
                            ? this.updateExplanationVideo(
                                  explanationConfig?.incorrectVideoResource?.videoResourceElement,
                                  questionExplanationConfig?.incorrectVideoResource?.videoResourceId
                              )
                            : null,
                        videoSubtitle: explanationConfig?.incorrectVideoResource?.videoSubtitle
                            ? this.updateExplanationVideoSubtitle(
                                  explanationConfig?.incorrectVideoResource?.videoSubtitle,
                                  questionExplanationConfig?.incorrectVideoResource?.videoSubtitle?.subtitleResourceId
                              )
                            : null,
                    },
                    partialCorrectVideoResource: {
                        ...explanationConfig?.partialCorrectVideoResource,
                        resourceType: 'VIDEO',
                        videoResourceId: explanationConfig?.partialCorrectVideoResource?.videoResourceElement
                            ? this.updateExplanationVideo(
                                  explanationConfig?.partialCorrectVideoResource?.videoResourceElement,
                                  questionExplanationConfig?.partialCorrectVideoResource?.videoResourceId
                              )
                            : null,
                        videoSubtitle: explanationConfig?.partialCorrectVideoResource?.videoSubtitle
                            ? this.updateExplanationVideoSubtitle(
                                  explanationConfig?.partialCorrectVideoResource?.videoSubtitle,
                                  questionExplanationConfig?.partialCorrectVideoResource?.videoSubtitle
                                      ?.subtitleResourceId
                              )
                            : null,
                    },
                };
            }
            case 'AUDIO': {
                const explanationConfig = resultExplanationConfig as IResultWithAudioExplanation;
                const questionExplanationConfig = this.data?.question
                    ?.resultExplanationConfig as ResultWithAudioExplanation;
                return {
                    explanationType: 'RESULT_WITH_AUDIO',
                    correctAudioResource: {
                        ...explanationConfig?.correctAudioResource,
                        resourceType: 'AUDIO',
                        resourceId: explanationConfig?.correctAudioResource?.audioResourceElement
                            ? this.updateExplanationAudio(
                                  explanationConfig?.correctAudioResource?.audioResourceElement,
                                  questionExplanationConfig?.correctAudioResource?.resourceId
                              )
                            : null,
                    },
                    incorrectAudioResource: {
                        ...explanationConfig?.incorrectAudioResource,
                        resourceType: 'AUDIO',
                        resourceId: explanationConfig?.incorrectAudioResource?.audioResourceElement
                            ? this.updateExplanationAudio(
                                  explanationConfig?.incorrectAudioResource?.audioResourceElement,
                                  questionExplanationConfig?.incorrectAudioResource?.resourceId
                              )
                            : null,
                    },
                    partialCorrectAudioResource: {
                        ...explanationConfig?.partialCorrectAudioResource,
                        resourceType: 'AUDIO',
                        resourceId: explanationConfig?.partialCorrectAudioResource?.audioResourceElement
                            ? this.updateExplanationAudio(
                                  explanationConfig?.partialCorrectAudioResource?.audioResourceElement,
                                  questionExplanationConfig?.partialCorrectAudioResource?.resourceId
                              )
                            : null,
                    },
                };
            }
            default: {
                return null;
            }
        }
    }

    abstract updateAnswers(questionData: Question, answers: any[]): any[];

    updateWidgetData(newData: RealTimeForm | DetailForm): void {
        const modifiedData = this.transformModifiedData(newData);

        const { answers, ...detailData } = modifiedData;

        const {
            attempts,
            enableAnswerExplanation,
            enableCustomExplanation,
            enableGeneralExplanation,
            fulfillment,
            fulfillmentAvailable,
            mediaResourceElement,
            pdfResourceElement,
            videoResourceElement,
            audioResourceElement,
            videoSubtitle,
            generalExplanationType,
            generalExplanationConfig,
            resultExplanationType,
            resultExplanationConfig,
            ...questionData
        } = detailData as any;

        // * Cast the elem so we will have type safety and autocomplete
        const tempQuestionData: SingleChoiceQuestion = { ...questionData };

        // * Update answers
        const updatedAnswers = this.updateAnswers(tempQuestionData, answers);

        // * Update pdf
        const { pdfResourceId, pdfOriginalFileName } = this.updatePdf(tempQuestionData, pdfResourceElement);

        // * Update media
        const mediaResourceId = this.updateMedia(tempQuestionData, mediaResourceElement);

        // * Update video
        const videoResourceId = this.updateVideo(tempQuestionData, videoResourceElement);

        // * Update audio
        const audioResourceId = this.updateAudio(tempQuestionData, audioResourceElement);

        // * Update subtitle
        const subtitleResource = this.updateSubtitle(tempQuestionData, videoSubtitle);

        // * Update general explanation
        const generalExplanation = this.updateGeneralExplanation(generalExplanationType, generalExplanationConfig);

        // * Update result explanation
        const resultExplanation = this.updateResultExplanation(resultExplanationType, resultExplanationConfig);

        const hasExplanation = tempQuestionData.hasExplanation ?? this.data.question.hasExplanation;
        const hasCustomExplanation = tempQuestionData.hasCustomExplanation ?? this.data.question.hasCustomExplanation;
        // * Update QuestionData
        const question: SingleChoiceQuestion = {
            ...this.data.question,
            ...tempQuestionData,
            pdfResourceId,
            mediaResourceId,
            pdfOriginalFileName,
            explanation: hasExplanation ? tempQuestionData.explanation ?? this.data.question.explanation : null,
            explanationCorrect: hasCustomExplanation
                ? tempQuestionData.explanationCorrect ?? this.data.question.explanationCorrect
                : null,
            explanationIncorrect: hasCustomExplanation
                ? tempQuestionData.explanationIncorrect ?? this.data.question.explanationIncorrect
                : null,
            explanationPartialCorrect: hasCustomExplanation
                ? tempQuestionData.explanationPartialCorrect ?? this.data.question.explanationPartialCorrect
                : null,
            answers: updatedAnswers,
            videoResourceId: videoResourceId,
            audioResourceId: audioResourceId,
            videoSubtitle: subtitleResource,
            generalExplanationConfig: generalExplanation,
            resultExplanationConfig: resultExplanation,
            videoControlsEnabled: videoResourceId ? true : null,
        };

        // *  Widget level data
        const widgetLevelData = {
            attempts: attempts ?? this.data.attempts,
            enableAnswerExplanation: tempQuestionData.hasAnswerExplanation, // enableAnswerExplanation ?? this.data.enableAnswerExplanation,
            enableCustomExplanation: question.hasCustomExplanation, // enableCustomExplanation ?? this.data.enableCustomExplanation,
            enableGeneralExplanation: question.hasExplanation, // enableGeneralExplanation ?? this.data.enableGeneralExplanation,
            fulfillmentAvailable: fulfillmentAvailable ?? this.data.fulfillmentAvailable,
            fulfillment: fulfillment ?? this.data.fulfillment,
        };

        this.data = { ...this.data, ...widgetLevelData, question };
    }

    getWidgetModifyRequest(): WidgetModifyParams<T> {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { answers, ...rest } = this.data;
        const question = { ...rest.question };

        return {
            widgetId: this.id,
            body: { data: { ...rest, question } as T },
        };
    }

    removeResourceIds(resourceIds: string[]): void {
        resourceIds.forEach((id: string) => {
            if (this.data.question.pdfResourceId === id) {
                this.data = {
                    ...this.data,
                    question: { ...this.data.question, pdfResourceId: null, pdfOriginalFileName: null },
                };
                this.fileUploadElement = null;
            } else if (this.data.question.mediaResourceId === id) {
                this.data = { ...this.data, question: { ...this.data.question, mediaResourceId: null } };
                this.mediaUploadElement = null;
            } else if (this.data.question.videoResourceId === id) {
                this.data = { ...this.data, question: { ...this.data.question, videoResourceId: null } };
                this.videoUploadElement = null;
            } else if (this.data.question.audioResourceId === id) {
                this.data = { ...this.data, question: { ...this.data.question, audioResourceId: null } };
                this.audioUploadElement = null;
            } else if (this.data.question.videoSubtitle?.subtitleResourceId === id) {
                this.data = {
                    ...this.data,
                    question: {
                        ...this.data.question,
                        videoSubtitle: { originalFileName: null, srcLang: null, subtitleResourceId: null },
                    },
                };
                this.videoSubtitleElement = null;
            } else if (this.data.answers) {
                const answer = this.data.answers.find((answerElem) => answerElem.data.mediaResourceId === id);
                if (answer) {
                    const updateElem = {
                        ...answer.data,
                        mediaResourceElement: { fileItem: null, elementId: null },
                    };
                    answer.updateData(updateElem);
                }
            }
        });

        if (this.data.answers) {
            this.data.question.answers = this.data.answers.map((e) => e.getDataForSave());
        }
    }
}
