import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageControllerService, WidgetControllerService } from '@apiServices';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { forkJoin } from 'rxjs';
import { RealTimeRecommendationButtonWidgetForm } from 'src/app/api-wrapper/models/widget-models/recommendation-button-widget-model';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';
import { IPath, PathSelectorComponent } from '../path-selector/path-selector.component';

type ButtonStateType = 'LINK' | 'EDIT';

@Component({
    selector: 'app-recommendation-button-real-time-widget',
    templateUrl: './recommendation-button-real-time-widget.component.html',
    styleUrls: ['./recommendation-button-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RecommendationButtonRealTimeWidgetComponent),
            multi: true,
        },
    ],
})
export class RecommendationButtonRealTimeWidgetComponent
    extends AbstractFormBaseComponent<RealTimeRecommendationButtonWidgetForm, IWidgetSupport>
    implements OnInit
{
    @ViewChild('hiddenText') textEl: ElementRef;
    @Input() editorLocation: 'real-time' | 'detail' = 'real-time';
    testEnv = false;

    minWidth = 180;
    width = this.minWidth;
    state: ButtonStateType;

    get align(): string {
        return this.form && this.form.get('align').value;
    }

    get pageId(): number {
        return this.form.get('pageId').value as number;
    }

    get widgetId(): string {
        return this.form.get('widgetId').value as string;
    }

    constructor(
        public dialog: MatDialog,
        private widgetSrv: WidgetControllerService,
        private pageSrv: PageControllerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.state = this.pageId ? 'LINK' : 'EDIT';
    }

    createForm(): FormGroupTyped<RealTimeRecommendationButtonWidgetForm> {
        return new FormGroupTyped<RealTimeRecommendationButtonWidgetForm>({
            text: FormHelper.controlFactoryWithCalculatedValue(null),
            align: FormHelper.controlFactoryWithCalculatedValue(null),
            pageId: FormHelper.controlFactoryWithCalculatedValue(null),
            widgetId: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    openPageLinkHandler(): void {
        forkJoin({
            anchors: this.widgetSrv.getAnchors({ courseId: this.config.courseId }),
            pages: this.pageSrv.getAllPagesByCourseId({ courseId: this.config.courseId }),
        }).subscribe(({ anchors, pages }) => {
            const dialogRef = this.dialog.open(PathSelectorComponent, {
                data: {
                    titlePath: 'COURSES.COURSE_HANDLER',
                    pages: pages.filter((page) => page.id !== Number(this.config.pageId)),
                    anchors: anchors,
                },
            });

            dialogRef.afterClosed().subscribe((result: IPath) => {
                if (!result) {
                    return;
                }
                this.form.patchValue({
                    ...(result.name ? { text: result.name } : {}),
                    pageId: result.pageId,
                    widgetId: result.widgetId,
                });
                this.state = 'LINK';
            });
        });
    }

    configDataChanged(): void {
        this.openPageLinkHandler();
    }

    toogleState(): void {
        this.state = this.state === 'EDIT' ? 'LINK' : 'EDIT';
        if (this.state === 'EDIT') {
            this.setFocusOnInput();
        }
    }

    setFocusOnInput(): void {
        setTimeout(() => {
            (document.querySelector(`#${this.config?.focusElemId}`) as HTMLInputElement).focus();
        }, 0);
    }

    updateAlign(align: 'LEFT' | 'CENTER' | 'RIGHT'): void {
        this.form.patchValue({ align });
    }

    resize(time = 0): void {
        setTimeout(() => {
            if (this.textEl) {
                this.width = Math.max(this.minWidth, this.textEl.nativeElement.offsetWidth);
                this.changeDetectorRef.detectChanges();
            }
        }, time);
    }
}
