import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageResponse } from '@apiModels';
import { CourseControllerService, PageControllerService, WidgetControllerService } from '@apiServices';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { RealTimeRecommendationWidgetForm } from 'src/app/api-wrapper/models/widget-models/recommendation-widget.model';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

import { RecommendationPageSelectorComponent } from '../components/recommendation-page-selector/recommendation-page-selector.component';
import { forkJoin } from 'rxjs';
import { IPath, PathSelectorComponent } from '../../recommendation-button-widget/path-selector/path-selector.component';

@Component({
    selector: 'app-recommendation-real-time-widget',
    templateUrl: './recommendation-real-time-widget.component.html',
    styleUrls: ['./recommendation-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RecommendationRealTimeWidgetComponent),
            multi: true,
        },
    ],
})
export class RecommendationRealTimeWidgetComponent extends AbstractFormBaseComponent<
    RealTimeRecommendationWidgetForm,
    IWidgetSupport
> {
    @Input() editorLocation: 'real-time' | 'detail' = 'real-time';
    testEnv = false;

    constructor(
        public dialog: MatDialog,
        private pageSrv: PageControllerService,
        private widgetSrv: WidgetControllerService,
        private courseSrv: CourseControllerService
    ) {
        super();
    }

    createForm(): FormGroupTyped<RealTimeRecommendationWidgetForm> {
        return new FormGroupTyped<RealTimeRecommendationWidgetForm>({
            text: FormHelper.controlFactoryWithCalculatedValue(null),
            description: FormHelper.controlFactoryWithCalculatedValue(null),
            layout: FormHelper.controlFactoryWithCalculatedValue('TEXT_AND_CARD'),
            pageId: FormHelper.controlFactoryWithCalculatedValue(null),
            widgetId: FormHelper.controlFactoryWithCalculatedValue(null),
            name: FormHelper.controlFactoryWithCalculatedValue(null),
            coverImageResourceId: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    openPageLinkHandler(): void {
        forkJoin({
            anchors: this.widgetSrv.getAnchors({ courseId: this.config.courseId }),
            pages: this.pageSrv.getAllPagesByCourseId({ courseId: this.config.courseId }),
        }).subscribe(({ anchors, pages }) => {
            const dialogRef = this.dialog.open(PathSelectorComponent, {
                data: {
                    titlePath: 'COURSES.COURSE_HANDLER',
                    pages: pages,
                    anchors: anchors,
                },
            });

            dialogRef.afterClosed().subscribe((result: IPath) => {
                if (!result) {
                    return;
                }
                if (result.page.parentPageId) {
                    this.form.patchValue({
                        ...(result.name ? { text: result.name } : {}),
                        pageId: result.page.id,
                        widgetId: result.widgetId,
                        name: result.page.name,
                        coverImageResourceId: result.page.data.coverImageResourceId,
                    });
                } else {
                    // ha a főoldalra mutat a horgony, akkor a kurzus borítót töltjük be
                    this.courseSrv.getCourse({ courseId: result.page.parentCourseId }).subscribe((c) => {
                        this.form.patchValue({
                            ...(result.name ? { text: result.name } : {}),
                            pageId: result.page.id,
                            widgetId: result.widgetId,
                            name: result.page.name,
                            coverImageResourceId: c.coverImageResourceId,
                        });
                    });
                }
            });
        });
    }

    configDataChanged(): void {
        this.openPageLinkHandler();
    }
}
