import { Type } from '@angular/core';
import { AbstractWidgetData } from '@apiModels';
import { AnchorWidgetClass } from 'src/app/api-wrapper/models/widget-models/anchor-widget-model';
import { BaseWidgetClass } from 'src/app/api-wrapper/models/widget-models/base-widget-models/base-widget-class';
import { CarouselWidgetClass } from 'src/app/api-wrapper/models/widget-models/carousel-widget-model';
import { DocumentWidgetClass } from 'src/app/api-wrapper/models/widget-models/document-widget-model';
import { EmbeddedDocumentWidgetClass } from 'src/app/api-wrapper/models/widget-models/embeded-widgets/embedded-document-model';
import { IframeWidgetClass } from 'src/app/api-wrapper/models/widget-models/embeded-widgets/iframe-widget-model';
import { YoutubeWidgetClass } from 'src/app/api-wrapper/models/widget-models/embeded-widgets/youtube-widget.model';
import { ImageTextWidgetClass } from 'src/app/api-wrapper/models/widget-models/image-text-widget-model';
import { ImageWidgetClass } from 'src/app/api-wrapper/models/widget-models/image-widget-model';
import { AudioTextWidgetClass } from 'src/app/api-wrapper/models/widget-models/multi-widgets/audio-text-widget-model';
import { DocumentTextWidgetClass } from 'src/app/api-wrapper/models/widget-models/multi-widgets/document-text-widget-model';
import { ImageImageWidgetClass } from 'src/app/api-wrapper/models/widget-models/multi-widgets/image-image-widget-model';
import { NavigationBlockWidgetClass } from 'src/app/api-wrapper/models/widget-models/navigation-block-widget.model';
import { ClozeQuestionWidgetClass } from 'src/app/api-wrapper/models/widget-models/questions/cloze-question/cloze-question-widget-model';
import { GroupQuestionWidgetModel } from 'src/app/api-wrapper/models/widget-models/questions/group-question/group-question-widget-model';
import { MatchingQuestionWidgetModel } from 'src/app/api-wrapper/models/widget-models/questions/matching-question/matching-question-widget-model';
import { SortingQuestionWidgetModel } from 'src/app/api-wrapper/models/widget-models/questions/sorting-question/sorting-question-widget-model';
import { RecommendationButtonWidgetClass } from 'src/app/api-wrapper/models/widget-models/recommendation-button-widget-model';
import { RecommendationWidgetClass } from 'src/app/api-wrapper/models/widget-models/recommendation-widget.model';
import { TextWidgetClass } from 'src/app/api-wrapper/models/widget-models/text-widget-model';
import { VideoTextWidgetClass } from 'src/app/api-wrapper/models/widget-models/video-text-widget.model';

import { AudioWidgetClass } from '../../api-wrapper/models/widget-models/audio-widget-model';
import { ConceptCardWidgetClass } from '../../api-wrapper/models/widget-models/concept-card-widget-model';
import { InfoCardWidgetClass } from '../../api-wrapper/models/widget-models/info-card-widget-model';
import { LearningCardWidgetClass } from '../../api-wrapper/models/widget-models/learning-card-widget-model';
import { NoteWidgetClass } from '../../api-wrapper/models/widget-models/note-widget-model';
import { ChoiceQuestionWidgetClass } from '../../api-wrapper/models/widget-models/questions/choice-question/choice-question-widget-model';
import { VideoWidgetClass } from '../../api-wrapper/models/widget-models/video-widget-model';
import { AnchorRealTimeWidgetComponent } from '../widgets/anchor-widget/anchor-real-time-widget/anchor-real-time-widget.component';
import { AudioTextDetailWidgetComponent } from '../widgets/AudioTextWidget/audio-text-detail-widget/audio-text-detail-widget.component';
import { AudioTextRealTimeWidgetComponent } from '../widgets/AudioTextWidget/audio-text-real-time-widget/audio-text-real-time-widget.component';
import { AudioDetailWidgetComponent } from '../widgets/AudioWidget/audio-detail-widget/audio-detail-widget.component';
import { AudioRealTimeWidgetComponent } from '../widgets/AudioWidget/audio-real-time-widget/audio-real-time-widget.component';
import { CarouselDetailWidgetComponent } from '../widgets/CarouselWidget/carousel-detail-widget/carousel-detail-widget.component';
import { CarouselRealTimeWidgetComponent } from '../widgets/CarouselWidget/carousel-real-time-widget/carousel-real-time-widget.component';
import { ConceptCardRealTimeWidgetComponent } from '../widgets/ConceptCardWidget/concept-card-real-time-widget/concept-card-real-time-widget.component';
import { DocumentTextDetailWidgetComponent } from '../widgets/DocumentTextWidget/document-text-detail-widget/document-text-detail-widget.component';
import { DocumentTextRealTimeWidgetComponent } from '../widgets/DocumentTextWidget/document-text-real-time-widget/document-text-real-time-widget.component';
import { DocumentDetailWidgetComponent } from '../widgets/DocumentWidget/document-detail-widget/document-detail-widget.component';
import { DocumentRealTimeWidgetComponent } from '../widgets/DocumentWidget/document-real-time-widget/document-real-time-widget.component';
import { EmbeddedDocumentDetailWidgetComponent } from '../widgets/EmbededWidgets/EmbeddedDocumentWidget/embedded-document-detail-widget/embedded-document-detail-widget.component';
import { EmbeddedDocumentRealTimeWidgetComponent } from '../widgets/EmbededWidgets/EmbeddedDocumentWidget/embedded-document-real-time-widget/embedded-document-real-time-widget.component';
import { IframeDetailWidgetComponent } from '../widgets/EmbededWidgets/IframeWidget/iframe-detail-widget/iframe-detail-widget.component';
import { IframeRealTimeWidgetComponent } from '../widgets/EmbededWidgets/IframeWidget/iframe-real-time-widget/iframe-real-time-widget.component';
import { YoutubeDetailWidgetComponent } from '../widgets/EmbededWidgets/YoutubeWidget/youtube-detail-widget/youtube-detail-widget.component';
import { YoutubeRealTimeWidgetComponent } from '../widgets/EmbededWidgets/YoutubeWidget/youtube-real-time-widget/youtube-real-time-widget.component';
import { ImageImageDetailWidgetComponent } from '../widgets/image-image-widget/image-image-detail-widget/image-image-detail-widget.component';
import { ImageImageRealTimeWidgetComponent } from '../widgets/image-image-widget/image-image-real-time-widget/image-image-real-time-widget.component';
import { ImageTextDetailWidgetComponent } from '../widgets/ImageTextWidget/image-text-detail-widget/image-text-detail-widget.component';
import { ImageTextRealTimeWidgetComponent } from '../widgets/ImageTextWidget/image-text-real-time-widget/image-text-real-time-widget.component';
import { ImageDetailWidgetComponent } from '../widgets/ImageWidget/image-detail-widget/image-detail-widget.component';
import { ImageRealTimeWidgetComponent } from '../widgets/ImageWidget/image-real-time-widget/image-real-time-widget.component';
import { InfoCardRealTimeWidgetComponent } from '../widgets/InfoCardWidget/info-card-real-time-widget/info-card-real-time-widget.component';
import { LearningCardDetailWidgetComponent } from '../widgets/LearningCardWidget/learning-card-detail-widget/learning-card-detail-widget.component';
import { LearningCardRealTimeWidgetComponent } from '../widgets/LearningCardWidget/learning-card-real-time-widget/learning-card-real-time-widget.component';
import { NavigationBlockDetailWidgetComponent } from '../widgets/NavigationBlockWidget/navigation-block-detail-widget/navigation-block-detail-widget.component';
import { NavigationBlockRealTimeWidgetComponent } from '../widgets/NavigationBlockWidget/navigation-block-real-time-widget/navigation-block-real-time-widget.component';
import { NoteDetailWidgetComponent } from '../widgets/NoteWidget/note-detail-widget/note-detail-widget.component';
import { NoteRealTimeWidgetComponent } from '../widgets/NoteWidget/note-real-time-widget/note-real-time-widget.component';
import { CloseQuestionDetailWidgetComponent } from '../widgets/QuestionWidget/CloseQuestionWidget/close-question-detail-widget/close-question-detail-widget.component';
import { CloseQuestionRealTimeWidgetComponent } from '../widgets/QuestionWidget/CloseQuestionWidget/close-question-real-time-widget/close-question-real-time-widget.component';
import { GroupQuestionDetailWidgetComponent } from '../widgets/QuestionWidget/GroupQuestionWidget/group-question-detail-widget/group-question-detail-widget.component';
import { GroupQuestionRealTimeWidgetComponent } from '../widgets/QuestionWidget/GroupQuestionWidget/group-question-real-time-widget/group-question-real-time-widget.component';
import { MatchingQuestionDetailWidgetComponent } from '../widgets/QuestionWidget/MatchingQuestionWidget/matching-question-detail-widget/matching-question-detail-widget.component';
import { MatchingQuestionRealTimeWidgetComponent } from '../widgets/QuestionWidget/MatchingQuestionWidget/matching-question-real-time-widget/matching-question-real-time-widget.component';
import { MultiChoiceQuestionDetailWidgetComponent } from '../widgets/QuestionWidget/MultiChoiceQuestionWidget/multi-choice-question-detail-widget/multi-choice-question-detail-widget.component';
import { MultiChoiceQuestionRealTimeWidgetComponent } from '../widgets/QuestionWidget/MultiChoiceQuestionWidget/multi-choice-question-real-time-widget/multi-choice-question-real-time-widget.component';
import { SingleChoiceQuestionDetailWidgetComponent } from '../widgets/QuestionWidget/SingleChoiceQuestionWidget/single-choice-question-detail-widget/single-choice-question-detail-widget.component';
import { SingleChoiceQuestionRealTimeWidgetComponent } from '../widgets/QuestionWidget/SingleChoiceQuestionWidget/single-choice-question-real-time-widget/single-choice-question-real-time-widget.component';
import { SortingQuestionDetailWidgetComponent } from '../widgets/QuestionWidget/SortingQuestionWidget/sorting-question-detail-widget/sorting-question-detail-widget.component';
import { SortingQuestionRealTimeWidgetComponent } from '../widgets/QuestionWidget/SortingQuestionWidget/sorting-question-real-time-widget/sorting-question-real-time-widget.component';
import { RecommendationButtonRealTimeWidgetComponent } from '../widgets/recommendation-button-widget/recommendation-button-real-time-widget/recommendation-button-real-time-widget.component';
import { RecommendationRealTimeWidgetComponent } from '../widgets/recommendation-widget/recommendation-real-time-widget/recommendation-real-time-widget.component';
import { TextRealTimeWidgetComponent } from '../widgets/TextWidget/text-real-time-widget/text-real-time-widget.component';
import { VideoTextDetailWidgetComponent } from '../widgets/VideoTextWidget/video-text-detail-widget/video-text-detail-widget.component';
import { VideoTextRealTimeWidgetComponent } from '../widgets/VideoTextWidget/video-text-real-time-widget/video-text-real-time-widget.component';
import { VideoDetailWidgetComponent } from '../widgets/VideoWidget/video-detail-widget/video-detail-widget.component';
import { VideoRealTimeWidgetComponent } from '../widgets/VideoWidget/video-real-time-widget/video-real-time-widget.component';
import { ExamRealTimeWidgetComponent } from '../widgets/ExamWidget/exam-real-time-widget/exam-real-time-widget.component';
import { ExamDetailWidgetComponent } from '../widgets/ExamWidget/exam-detail-widget/exam-detail-widget.component';
import { ExamWidgetClass } from '../../api-wrapper/models/widget-models/exam-widget-model';
import { RandomQuestionRealTimeWidgetComponent } from '../widgets/RandomQuestionWidget/random-question-real-time-widget/random-question-real-time-widget.component';
import { RandomQuestionDetailWidgetComponent } from '../widgets/RandomQuestionWidget/random-question-detail-widget/random-question-detail-widget.component';
import { RandomQuestionWidgetClass } from '../../api-wrapper/models/widget-models/random-question-model';

// SORTING_QUESTION  MATCHING_QUESTION
export type WidgetType =
    | 'TEXT_WIDGET'
    | 'SIMPLE_IMAGE_WIDGET'
    | 'CHAPTER_WIDGET'
    | 'LESSON_WIDGET'
    | 'SIMPLE_VIDEO_WIDGET'
    | 'AUDIO_WIDGET'
    | 'LEARNING_CARD_WIDGET'
    | 'CONCEPT_WIDGET'
    | 'DOCUMENT_WIDGET'
    | 'INFO_WIDGET'
    | 'NOTE_WIDGET'
    | 'QUESTION_WIDGET'
    | 'SINGLE_CHOICE_QUESTION'
    | 'MULTI_CHOICE_QUESTION'
    | 'SORTING_QUESTION'
    | 'MATCHING_QUESTION'
    | 'GROUP_QUESTION'
    | 'DROP_DOWN_CLOZE_QUESTION'
    | 'NAVIGATION_BLOCK_WIDGET'
    | 'CAROUSEL_WIDGET'
    | 'GALLERY_WIDGET'
    | 'IMAGE_TEXT_WIDGET'
    | 'IMAGE_IMAGE_WIDGET'
    | 'VIDEO_TEXT_WIDGET'
    | 'AUDIO_TEXT_WIDGET'
    | 'DOCUMENT_TEXT_WIDGET'
    | 'IFRAME_WIDGET'
    | 'YOUTUBE_WIDGET'
    | 'EMBEDDED_DOCUMENT_WIDGET'
    | 'PAGE_RECOMMENDATION_WIDGET'
    | 'RECOMMENDATION_BUTTON_WIDGET'
    | 'ANCHOR_WIDGET'
    | 'EXAM_WIDGET'
    | 'RANDOM_QUESTION_WIDGET';

export type RealTimeWidgetType =
    | TextRealTimeWidgetComponent
    | ImageRealTimeWidgetComponent
    | VideoRealTimeWidgetComponent
    | AudioRealTimeWidgetComponent
    | LearningCardRealTimeWidgetComponent
    | ConceptCardRealTimeWidgetComponent
    | InfoCardRealTimeWidgetComponent
    | RecommendationRealTimeWidgetComponent
    | NavigationBlockRealTimeWidgetComponent
    | SortingQuestionRealTimeWidgetComponent
    | MultiChoiceQuestionRealTimeWidgetComponent
    | SingleChoiceQuestionRealTimeWidgetComponent
    | MatchingQuestionRealTimeWidgetComponent
    | GroupQuestionRealTimeWidgetComponent
    | CloseQuestionRealTimeWidgetComponent
    | NoteRealTimeWidgetComponent
    | CarouselRealTimeWidgetComponent
    | ImageTextRealTimeWidgetComponent
    | ImageImageRealTimeWidgetComponent
    | VideoTextRealTimeWidgetComponent
    | AudioTextRealTimeWidgetComponent
    | DocumentTextRealTimeWidgetComponent
    | IframeRealTimeWidgetComponent
    | EmbeddedDocumentRealTimeWidgetComponent
    | YoutubeRealTimeWidgetComponent
    | DocumentRealTimeWidgetComponent
    | AnchorRealTimeWidgetComponent
    | RecommendationButtonRealTimeWidgetComponent
    | ExamRealTimeWidgetComponent
    | RandomQuestionRealTimeWidgetComponent;

export type DetailWidgetType =
    | ImageDetailWidgetComponent
    | VideoDetailWidgetComponent
    | AudioDetailWidgetComponent
    | LearningCardDetailWidgetComponent
    | DocumentDetailWidgetComponent
    | NoteDetailWidgetComponent
    | SingleChoiceQuestionDetailWidgetComponent
    | MultiChoiceQuestionDetailWidgetComponent
    | SortingQuestionDetailWidgetComponent
    | MatchingQuestionDetailWidgetComponent
    | GroupQuestionDetailWidgetComponent
    | CloseQuestionDetailWidgetComponent
    | CarouselDetailWidgetComponent
    | ImageTextDetailWidgetComponent
    | ImageImageDetailWidgetComponent
    | VideoTextDetailWidgetComponent
    | AudioTextDetailWidgetComponent
    | DocumentTextDetailWidgetComponent
    | IframeDetailWidgetComponent
    | EmbeddedDocumentDetailWidgetComponent
    | YoutubeDetailWidgetComponent
    | NavigationBlockDetailWidgetComponent
    | ExamDetailWidgetComponent
    | RandomQuestionDetailWidgetComponent;

export interface WidgetConfigInterface {
    realTimeWidgetComponent: Type<RealTimeWidgetType>;
    detailEditorComponent?: Type<DetailWidgetType>;
    model: Type<BaseWidgetClass<AbstractWidgetData>>;
}

export const WidgetConfig = new Map<WidgetType, WidgetConfigInterface>([
    [
        'TEXT_WIDGET',
        {
            realTimeWidgetComponent: TextRealTimeWidgetComponent,
            model: TextWidgetClass,
        },
    ],
    [
        'SIMPLE_IMAGE_WIDGET',
        {
            detailEditorComponent: ImageDetailWidgetComponent,
            realTimeWidgetComponent: ImageRealTimeWidgetComponent,
            model: ImageWidgetClass,
        },
    ],
    [
        'SIMPLE_VIDEO_WIDGET',
        {
            detailEditorComponent: VideoDetailWidgetComponent,
            realTimeWidgetComponent: VideoRealTimeWidgetComponent,
            model: VideoWidgetClass,
        },
    ],
    [
        'AUDIO_WIDGET',
        {
            detailEditorComponent: AudioDetailWidgetComponent,
            realTimeWidgetComponent: AudioRealTimeWidgetComponent,
            model: AudioWidgetClass,
        },
    ],
    [
        'LEARNING_CARD_WIDGET',
        {
            detailEditorComponent: LearningCardDetailWidgetComponent,
            realTimeWidgetComponent: LearningCardRealTimeWidgetComponent,
            model: LearningCardWidgetClass,
        },
    ],
    [
        'CONCEPT_WIDGET',
        {
            realTimeWidgetComponent: ConceptCardRealTimeWidgetComponent,
            model: ConceptCardWidgetClass,
        },
    ],
    [
        'INFO_WIDGET',
        {
            realTimeWidgetComponent: InfoCardRealTimeWidgetComponent,
            model: InfoCardWidgetClass,
        },
    ],
    [
        'DOCUMENT_WIDGET',
        {
            realTimeWidgetComponent: DocumentRealTimeWidgetComponent,
            detailEditorComponent: DocumentDetailWidgetComponent,
            model: DocumentWidgetClass,
        },
    ],
    [
        'NOTE_WIDGET',
        {
            realTimeWidgetComponent: NoteRealTimeWidgetComponent,
            detailEditorComponent: NoteDetailWidgetComponent,
            model: NoteWidgetClass,
        },
    ],
    [
        'SINGLE_CHOICE_QUESTION',
        {
            realTimeWidgetComponent: SingleChoiceQuestionRealTimeWidgetComponent,
            detailEditorComponent: SingleChoiceQuestionDetailWidgetComponent,
            model: ChoiceQuestionWidgetClass,
        },
    ],
    [
        'MULTI_CHOICE_QUESTION',
        {
            realTimeWidgetComponent: MultiChoiceQuestionRealTimeWidgetComponent,
            detailEditorComponent: MultiChoiceQuestionDetailWidgetComponent,
            model: ChoiceQuestionWidgetClass,
        },
    ],
    [
        'SORTING_QUESTION',
        {
            realTimeWidgetComponent: SortingQuestionRealTimeWidgetComponent,
            detailEditorComponent: SortingQuestionDetailWidgetComponent,
            model: SortingQuestionWidgetModel,
        },
    ],
    [
        'MATCHING_QUESTION',
        {
            realTimeWidgetComponent: MatchingQuestionRealTimeWidgetComponent,
            detailEditorComponent: MatchingQuestionDetailWidgetComponent,
            model: MatchingQuestionWidgetModel,
        },
    ],
    [
        'GROUP_QUESTION',
        {
            realTimeWidgetComponent: GroupQuestionRealTimeWidgetComponent,
            detailEditorComponent: GroupQuestionDetailWidgetComponent,
            model: GroupQuestionWidgetModel,
        },
    ],
    [
        'DROP_DOWN_CLOZE_QUESTION',
        {
            realTimeWidgetComponent: CloseQuestionRealTimeWidgetComponent,
            detailEditorComponent: CloseQuestionDetailWidgetComponent,
            model: ClozeQuestionWidgetClass,
        },
    ],
    [
        'NAVIGATION_BLOCK_WIDGET',
        {
            realTimeWidgetComponent: NavigationBlockRealTimeWidgetComponent,
            detailEditorComponent: NavigationBlockDetailWidgetComponent,
            model: NavigationBlockWidgetClass,
        },
    ],
    [
        'CAROUSEL_WIDGET',
        {
            realTimeWidgetComponent: CarouselRealTimeWidgetComponent,
            detailEditorComponent: CarouselDetailWidgetComponent,
            model: CarouselWidgetClass,
        },
    ],
    [
        'GALLERY_WIDGET',
        {
            realTimeWidgetComponent: CarouselRealTimeWidgetComponent,
            detailEditorComponent: CarouselDetailWidgetComponent,
            model: CarouselWidgetClass,
        },
    ],
    [
        'PAGE_RECOMMENDATION_WIDGET',
        {
            realTimeWidgetComponent: RecommendationRealTimeWidgetComponent,
            model: RecommendationWidgetClass,
        },
    ],
    [
        'IMAGE_TEXT_WIDGET',
        {
            realTimeWidgetComponent: ImageTextRealTimeWidgetComponent,
            detailEditorComponent: ImageTextDetailWidgetComponent,
            model: ImageTextWidgetClass,
        },
    ],
    [
        'VIDEO_TEXT_WIDGET',
        {
            realTimeWidgetComponent: VideoTextRealTimeWidgetComponent,
            detailEditorComponent: VideoTextDetailWidgetComponent,
            model: VideoTextWidgetClass,
        },
    ],
    [
        'AUDIO_TEXT_WIDGET',
        {
            realTimeWidgetComponent: AudioTextRealTimeWidgetComponent,
            detailEditorComponent: AudioTextDetailWidgetComponent,
            model: AudioTextWidgetClass,
        },
    ],
    [
        'DOCUMENT_TEXT_WIDGET',
        {
            realTimeWidgetComponent: DocumentTextRealTimeWidgetComponent,
            detailEditorComponent: DocumentTextDetailWidgetComponent,
            model: DocumentTextWidgetClass,
        },
    ],
    [
        'IFRAME_WIDGET',
        {
            realTimeWidgetComponent: IframeRealTimeWidgetComponent,
            detailEditorComponent: IframeDetailWidgetComponent,
            model: IframeWidgetClass,
        },
    ],
    [
        'YOUTUBE_WIDGET',
        {
            realTimeWidgetComponent: YoutubeRealTimeWidgetComponent,
            detailEditorComponent: YoutubeDetailWidgetComponent,
            model: YoutubeWidgetClass,
        },
    ],
    [
        'EMBEDDED_DOCUMENT_WIDGET',
        {
            realTimeWidgetComponent: EmbeddedDocumentRealTimeWidgetComponent,
            detailEditorComponent: EmbeddedDocumentDetailWidgetComponent,
            model: EmbeddedDocumentWidgetClass,
        },
    ],
    [
        'ANCHOR_WIDGET',
        {
            realTimeWidgetComponent: AnchorRealTimeWidgetComponent,
            model: AnchorWidgetClass,
        },
    ],
    [
        'RECOMMENDATION_BUTTON_WIDGET',
        {
            realTimeWidgetComponent: RecommendationButtonRealTimeWidgetComponent,
            model: RecommendationButtonWidgetClass,
        },
    ],
    [
        'IMAGE_IMAGE_WIDGET',
        {
            realTimeWidgetComponent: ImageImageRealTimeWidgetComponent,
            detailEditorComponent: ImageImageDetailWidgetComponent,
            model: ImageImageWidgetClass,
        },
    ],
    [
        'EXAM_WIDGET',
        {
            realTimeWidgetComponent: ExamRealTimeWidgetComponent,
            detailEditorComponent: ExamDetailWidgetComponent,
            model: ExamWidgetClass,
        },
    ],
    [
        'RANDOM_QUESTION_WIDGET',
        {
            realTimeWidgetComponent: RandomQuestionRealTimeWidgetComponent,
            detailEditorComponent: RandomQuestionDetailWidgetComponent,
            model: RandomQuestionWidgetClass,
        },
    ],
]);
