import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { DetailQuestionWidgetForm } from 'src/app/api-wrapper/models/widget-models/questions/init-question-detail-form';
import { ISubtitleFormData } from 'src/app/api-wrapper/models/widget-models/video-widget-model';

export type DetailQuestionWidgetFormType = Record<
    keyof DetailQuestionWidgetForm,
    UntypedFormControl | FormGroupTyped<any>
>;
export function createDetailQuestionForm(): DetailQuestionWidgetFormType {
    return {
        attempts: FormHelper.controlFactoryWithCalculatedValue(null),
        enableAnswerExplanation: FormHelper.controlFactoryWithCalculatedValue(null),
        enableCustomExplanation: FormHelper.controlFactoryWithCalculatedValue(null),
        enableGeneralExplanation: FormHelper.controlFactoryWithCalculatedValue(null),
        fulfillmentAvailable: FormHelper.controlFactoryWithCalculatedValue(null),
        fulfillment: FormHelper.controlFactoryWithCalculatedValue(null),
        explanation: FormHelper.controlFactoryWithCalculatedValue(null), // DEPRECATED
        explanationCorrect: FormHelper.controlFactoryWithCalculatedValue(null), // DEPRECATED
        explanationIncorrect: FormHelper.controlFactoryWithCalculatedValue(null), // DEPRECATED
        explanationPartialCorrect: FormHelper.controlFactoryWithCalculatedValue(null), // DEPRECATED
        hasAnswerExplanation: FormHelper.controlFactoryWithCalculatedValue(null),
        hasAnswerMedia: FormHelper.controlFactoryWithCalculatedValue(null),
        hasCustomExplanation: FormHelper.controlFactoryWithCalculatedValue(null),
        hasExplanation: FormHelper.controlFactoryWithCalculatedValue(null), // DEPRECATED
        hasMedia: FormHelper.controlFactoryWithCalculatedValue(null),
        hasVideo: FormHelper.controlFactoryWithCalculatedValue(null),
        hasAudio: FormHelper.controlFactoryWithCalculatedValue(null),
        hasPdf: FormHelper.controlFactoryWithCalculatedValue(null),
        pdfOriginalFileName: FormHelper.controlFactoryWithCalculatedValue(null),
        questionType: FormHelper.controlFactoryWithCalculatedValue(null),
        mediaDescription: FormHelper.controlFactoryWithCalculatedValue(null),
        pdfDescription: FormHelper.controlFactoryWithCalculatedValue(null),
        videoSubtitle: new UntypedFormGroup({
            element: FormHelper.controlFactoryWithCalculatedValue({
                fileItem: null,
                elementId: null,
            }),
            srcLang: FormHelper.controlFactoryWithCalculatedValue(null),
            originalFileName: FormHelper.controlFactoryWithCalculatedValue(null),
        }),
        videoTranscript: FormHelper.controlFactoryWithCalculatedValue(null),
        audioDescription: FormHelper.controlFactoryWithCalculatedValue(null),
        generalExplanationType: FormHelper.controlFactoryWithCalculatedValue(null),
        resultExplanationType: FormHelper.controlFactoryWithCalculatedValue(null),
        generalExplanationConfig: new UntypedFormGroup({
            explanationText: FormHelper.controlFactoryWithCalculatedValue(null),
            audioResource: initAudioResourceFormGroup(),
            videoResource: initVideoResourceFormGroup(),
        }),
        resultExplanationConfig: new UntypedFormGroup({
            explanationCorrectText: FormHelper.controlFactoryWithCalculatedValue(null),
            explanationIncorrectText: FormHelper.controlFactoryWithCalculatedValue(null),
            partialCorrectText: FormHelper.controlFactoryWithCalculatedValue(null),
            correctAudioResource: initAudioResourceFormGroup(),
            incorrectAudioResource: initAudioResourceFormGroup(),
            partialCorrectAudioResource: initAudioResourceFormGroup(),
            correctVideoResource: initVideoResourceFormGroup(),
            incorrectVideoResource: initVideoResourceFormGroup(),
            partialCorrectVideoResource: initVideoResourceFormGroup(),
        }),
    };
}

function initVideoResourceFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
        resourceType: FormHelper.controlFactoryWithCalculatedValue(null),
        previewImageResourceId: FormHelper.controlFactoryWithCalculatedValue(null),
        videoExpectedPosition: FormHelper.controlFactoryWithCalculatedValue(null),
        videoMuted: FormHelper.controlFactoryWithCalculatedValue(null),
        videoAutoplay: FormHelper.controlFactoryWithCalculatedValue(null),
        videoLoop: FormHelper.controlFactoryWithCalculatedValue(null),
        videoControlsEnabled: FormHelper.controlFactoryWithCalculatedValue(true),
        videoPlayAsGif: FormHelper.controlFactoryWithCalculatedValue(null),
        hasTranscript: FormHelper.controlFactoryWithCalculatedValue(null),
        videoAvailableScales: FormHelper.controlFactoryWithCalculatedValue([]),
        resourceIds: FormHelper.controlFactoryWithCalculatedValue([]),
        videoResourceId: FormHelper.controlFactoryWithCalculatedValue(null),
        videoResourceElement: FormHelper.controlFactoryWithCalculatedValue({
            fileItem: null,
            elementId: null,
        }),
        videoSubtitle: new UntypedFormGroup({
            element: FormHelper.controlFactoryWithCalculatedValue({
                fileItem: null,
                elementId: null,
            }),
            srcLang: FormHelper.controlFactoryWithCalculatedValue(null),
            originalFileName: FormHelper.controlFactoryWithCalculatedValue(null),
        }),
        videoTranscript: FormHelper.controlFactoryWithCalculatedValue(null),
    });
}

function initAudioResourceFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
        resourceType: FormHelper.controlFactoryWithCalculatedValue(null),
        resourceIds: FormHelper.controlFactoryWithCalculatedValue([]),
        description: FormHelper.controlFactoryWithCalculatedValue(null),
        resourceId: FormHelper.controlFactoryWithCalculatedValue(null),
        audioResourceElement: FormHelper.controlFactoryWithCalculatedValue({
            fileItem: null,
            elementId: null,
        }),
    });
}
