<form [formGroup]="form" class="embedded-document-widget" data-e2e="embedded-document-widget">
    <app-document-upload
        class="app-document-upload"
        [formControlName]="'element'"
        [courseId]="config?.courseId"
        [questionBankQuestion]="config?.questionBankId && config?.widgetId ? { questionBankId: config.questionBankId, questionId: config.widgetId } : null"
        [height]="height"
        [acceptTypes]="'application/pdf'"
        [regExp]="regExp"
        [title]="title"
    >
        <div class="embedded-document-widget__upload">
            <img src="assets/images/pdf_embed_empty-state.svg" class="embedded-document-widget__img" alt="" />
            <span class="embedded-document-widget__upload-text"
                >{{ 'DOCUMENT-WIDGET.DOCUMENT-DRAG-OR' | translate }}</span
            >
            <button type="button" class="embedded-document-widget__upload-button editor-btn section-btn border-2">
                {{ 'DOCUMENT-WIDGET.DOCUMENT-UPLOAD' | translate }}
            </button>
        </div>
    </app-document-upload>
</form>
