import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { DetailRandomQuestionForm } from '../../../../api-wrapper/models/widget-models/random-question-model';
import { IWidgetSupport } from '../../../models/widget-support-info.model';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { collapseAnimation } from '@shared/animations/collapse.animations';
import { IChekboxRadioFormModel } from '@shared/models/checkbox-radio-form.model';
import { CourseEditorHelpers } from '../../../helpers/fulfillment.helper';

@Component({
    selector: 'app-random-question-detail-widget',
    templateUrl: './random-question-detail-widget.component.html',
    styleUrls: ['./random-question-detail-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RandomQuestionDetailWidgetComponent),
            multi: true,
        },
    ],
    animations: [collapseAnimation],
})
export class RandomQuestionDetailWidgetComponent
    extends AbstractFormBaseComponent<DetailRandomQuestionForm, IWidgetSupport>
    implements OnInit
{
    fulfillmentAvailableOptions: IChekboxRadioFormModel[] = [
        { id: 'fulfillment-available-option-true', label: 'FORM-CONTROLS.YES', value: true },
        { id: 'fulfillment-available-option-false', label: 'FORM-CONTROLS.NO', value: false },
    ];

    fulfillmentOptions: IChekboxRadioFormModel[] = [
        {
            id: 'fulfillment-option-show',
            label: 'QUESTION-WIDGET.COMMON.MEASUREMENT.DISPLAY',
            value: 'SHOW',
            group: 'LAX',
        },
        {
            id: 'fulfillment-option-started',
            label: 'QUESTION-WIDGET.COMMON.MEASUREMENT.ANSWERED-QUESTION',
            value: 'QUESTION_ANSWERED',
            group: 'MEDIUM',
        },
        {
            id: 'fulfillment-option-elapsed',
            label: 'QUESTION-WIDGET.COMMON.MEASUREMENT.CORECTLY-ANSWERED-QUESTION',
            value: 'QUESTION_CORRECTLY_ANSWERED',
            group: 'STRICT',
        },
    ];

    constructor() {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initDisabledFields();
    }

    get attempts(): number {
        return this.form.get('attempts').value as number;
    }

    createForm(): FormGroupTyped<DetailRandomQuestionForm> {
        return new FormGroupTyped<DetailRandomQuestionForm>({
            realTimeRandomQuestionFormData: FormHelper.controlFactoryWithCalculatedValue(null),
            attempts: FormHelper.controlFactoryWithCalculatedValue(null),
            enableAnswerExplanation: FormHelper.controlFactoryWithCalculatedValue(null),
            enableCustomExplanation: FormHelper.controlFactoryWithCalculatedValue(null),
            enableGeneralExplanation: FormHelper.controlFactoryWithCalculatedValue(null),
            fulfillmentAvailable: FormHelper.controlFactoryWithCalculatedValue(null),
            fulfillment: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    initDisabledFields(): void {
        const value = this.form.get('fulfillmentAvailable').value as boolean;
        if (!value) {
            this.form.get('fulfillment').disable({ emitEvent: false });
        }
        this.subscriptions.add(
            this.form.get('fulfillmentAvailable').valueChanges.subscribe((fulfillmentAvailable: boolean) => {
                CourseEditorHelpers.handleFulfillment(
                    fulfillmentAvailable,
                    this.form,
                    this.config.fulfillmentSettings.fulfillmentLevel,
                    this.fulfillmentOptions
                );
            })
        );
    }
}
