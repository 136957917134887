/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DropDownClozeQuestion } from '../models/drop-down-cloze-question';
import { GroupQuestion } from '../models/group-question';
import { MatchingQuestion } from '../models/matching-question';
import { MultiChoiceQuestion } from '../models/multi-choice-question';
import { PageQuestionResponse } from '../models/page-question-response';
import { PageQuestionbankResponse } from '../models/page-questionbank-response';
import { QuestionResponse } from '../models/question-response';
import { QuestionbankRequest } from '../models/questionbank-request';
import { QuestionbankResponse } from '../models/questionbank-response';
import { ResourceResponse } from '../models/resource-response';
import { SingleChoiceQuestion } from '../models/single-choice-question';
import { SortingQuestion } from '../models/sorting-question';

@Injectable({
  providedIn: 'root',
})
export class QuestionbankControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getQuestionBanks1
   */
  static readonly GetQuestionBanks1Path = '/questionbanks/{questionBankId}';

  /**
   * Get questionbanks by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuestionBanks1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionBanks1$Response(params: {
    questionBankId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<QuestionbankResponse>> {

    const rb = new RequestBuilder(this.rootUrl, QuestionbankControllerService.GetQuestionBanks1Path, 'get');
    if (params) {
      rb.path('questionBankId', params.questionBankId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionbankResponse>;
      })
    );
  }

  /**
   * Get questionbanks by id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQuestionBanks1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionBanks1(params: {
    questionBankId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<QuestionbankResponse> {

    return this.getQuestionBanks1$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionbankResponse>) => r.body as QuestionbankResponse)
    );
  }

  /**
   * Path part for operation updateQuestionBank
   */
  static readonly UpdateQuestionBankPath = '/questionbanks/{questionBankId}';

  /**
   * Modify questionbank.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateQuestionBank()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateQuestionBank$Response(params: {
    questionBankId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: QuestionbankRequest
  }): Observable<StrictHttpResponse<QuestionbankResponse>> {

    const rb = new RequestBuilder(this.rootUrl, QuestionbankControllerService.UpdateQuestionBankPath, 'put');
    if (params) {
      rb.path('questionBankId', params.questionBankId, {});
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionbankResponse>;
      })
    );
  }

  /**
   * Modify questionbank.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateQuestionBank$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateQuestionBank(params: {
    questionBankId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: QuestionbankRequest
  }): Observable<QuestionbankResponse> {

    return this.updateQuestionBank$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionbankResponse>) => r.body as QuestionbankResponse)
    );
  }

  /**
   * Path part for operation deleteQuestionBank
   */
  static readonly DeleteQuestionBankPath = '/questionbanks/{questionBankId}';

  /**
   * Delete questionbank.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteQuestionBank()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteQuestionBank$Response(params: {
    questionBankId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QuestionbankControllerService.DeleteQuestionBankPath, 'delete');
    if (params) {
      rb.path('questionBankId', params.questionBankId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete questionbank.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteQuestionBank$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteQuestionBank(params: {
    questionBankId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.deleteQuestionBank$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getQuestionById
   */
  static readonly GetQuestionByIdPath = '/questionbanks/{questionBankId}/questions/{questionId}';

  /**
   * Get questions by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuestionById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionById$Response(params: {
    questionBankId: number;
    questionId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<QuestionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, QuestionbankControllerService.GetQuestionByIdPath, 'get');
    if (params) {
      rb.path('questionBankId', params.questionBankId, {});
      rb.path('questionId', params.questionId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionResponse>;
      })
    );
  }

  /**
   * Get questions by id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQuestionById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionById(params: {
    questionBankId: number;
    questionId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<QuestionResponse> {

    return this.getQuestionById$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionResponse>) => r.body as QuestionResponse)
    );
  }

  /**
   * Path part for operation modifyQuestion
   */
  static readonly ModifyQuestionPath = '/questionbanks/{questionBankId}/questions/{questionId}';

  /**
   * Modify question.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `modifyQuestion()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifyQuestion$Response(params: {
    questionBankId: number;
    questionId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: DropDownClozeQuestion | GroupQuestion | MatchingQuestion | MultiChoiceQuestion | SingleChoiceQuestion | SortingQuestion
  }): Observable<StrictHttpResponse<QuestionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, QuestionbankControllerService.ModifyQuestionPath, 'put');
    if (params) {
      rb.path('questionBankId', params.questionBankId, {});
      rb.path('questionId', params.questionId, {});
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionResponse>;
      })
    );
  }

  /**
   * Modify question.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `modifyQuestion$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifyQuestion(params: {
    questionBankId: number;
    questionId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: DropDownClozeQuestion | GroupQuestion | MatchingQuestion | MultiChoiceQuestion | SingleChoiceQuestion | SortingQuestion
  }): Observable<QuestionResponse> {

    return this.modifyQuestion$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionResponse>) => r.body as QuestionResponse)
    );
  }

  /**
   * Path part for operation deleteQuestionFromQuestionBank
   */
  static readonly DeleteQuestionFromQuestionBankPath = '/questionbanks/{questionBankId}/questions/{questionId}';

  /**
   * Delete question from questionbank.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteQuestionFromQuestionBank()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteQuestionFromQuestionBank$Response(params: {
    questionBankId: number;
    questionId: number;

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QuestionbankControllerService.DeleteQuestionFromQuestionBankPath, 'delete');
    if (params) {
      rb.path('questionBankId', params.questionBankId, {});
      rb.path('questionId', params.questionId, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('sort', params.sort, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete question from questionbank.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteQuestionFromQuestionBank$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteQuestionFromQuestionBank(params: {
    questionBankId: number;
    questionId: number;

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.deleteQuestionFromQuestionBank$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getQuestionBanks
   */
  static readonly GetQuestionBanksPath = '/questionbanks';

  /**
   * Search in questionbanks with textFragment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuestionBanks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionBanks$Response(params?: {
    textFragment?: string;

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<PageQuestionbankResponse>> {

    const rb = new RequestBuilder(this.rootUrl, QuestionbankControllerService.GetQuestionBanksPath, 'get');
    if (params) {
      rb.query('textFragment', params.textFragment, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('sort', params.sort, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageQuestionbankResponse>;
      })
    );
  }

  /**
   * Search in questionbanks with textFragment.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQuestionBanks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionBanks(params?: {
    textFragment?: string;

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<PageQuestionbankResponse> {

    return this.getQuestionBanks$Response(params).pipe(
      map((r: StrictHttpResponse<PageQuestionbankResponse>) => r.body as PageQuestionbankResponse)
    );
  }

  /**
   * Path part for operation createQuestionBank
   */
  static readonly CreateQuestionBankPath = '/questionbanks';

  /**
   * Create questionbank.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createQuestionBank()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createQuestionBank$Response(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: QuestionbankRequest
  }): Observable<StrictHttpResponse<QuestionbankResponse>> {

    const rb = new RequestBuilder(this.rootUrl, QuestionbankControllerService.CreateQuestionBankPath, 'post');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionbankResponse>;
      })
    );
  }

  /**
   * Create questionbank.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createQuestionBank$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createQuestionBank(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: QuestionbankRequest
  }): Observable<QuestionbankResponse> {

    return this.createQuestionBank$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionbankResponse>) => r.body as QuestionbankResponse)
    );
  }

  /**
   * Path part for operation getQuestionsByQuestionBank
   */
  static readonly GetQuestionsByQuestionBankPath = '/questionbanks/{questionBankId}/questions';

  /**
   * Search questions by questionbank.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuestionsByQuestionBank()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionsByQuestionBank$Response(params: {
    questionBankId: number;

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<PageQuestionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, QuestionbankControllerService.GetQuestionsByQuestionBankPath, 'get');
    if (params) {
      rb.path('questionBankId', params.questionBankId, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('sort', params.sort, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageQuestionResponse>;
      })
    );
  }

  /**
   * Search questions by questionbank.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQuestionsByQuestionBank$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionsByQuestionBank(params: {
    questionBankId: number;

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<PageQuestionResponse> {

    return this.getQuestionsByQuestionBank$Response(params).pipe(
      map((r: StrictHttpResponse<PageQuestionResponse>) => r.body as PageQuestionResponse)
    );
  }

  /**
   * Path part for operation addQuestionToQuestionBank
   */
  static readonly AddQuestionToQuestionBankPath = '/questionbanks/{questionBankId}/questions';

  /**
   * Add questions to questionbank.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addQuestionToQuestionBank()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addQuestionToQuestionBank$Response(params: {
    questionBankId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: DropDownClozeQuestion | GroupQuestion | MatchingQuestion | MultiChoiceQuestion | SingleChoiceQuestion | SortingQuestion
  }): Observable<StrictHttpResponse<QuestionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, QuestionbankControllerService.AddQuestionToQuestionBankPath, 'post');
    if (params) {
      rb.path('questionBankId', params.questionBankId, {});
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionResponse>;
      })
    );
  }

  /**
   * Add questions to questionbank.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addQuestionToQuestionBank$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addQuestionToQuestionBank(params: {
    questionBankId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: DropDownClozeQuestion | GroupQuestion | MatchingQuestion | MultiChoiceQuestion | SingleChoiceQuestion | SortingQuestion
  }): Observable<QuestionResponse> {

    return this.addQuestionToQuestionBank$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionResponse>) => r.body as QuestionResponse)
    );
  }

  /**
   * Path part for operation uploadResourceToQuestion
   */
  static readonly UploadResourceToQuestionPath = '/questionbanks/{questionBankId}/questions/{questionId}/resources';

  /**
   * Upload resource to question.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadResourceToQuestion()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadResourceToQuestion$Response(params: {

    /**
     * Identifier of questionbank
     */
    questionBankId: number;

    /**
     * Identifier of question
     */
    questionId: number;

    /**
     * Optional identifier of uploadable resource
     */
    resourceId?: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body?: { 'file': Blob }
  }): Observable<HttpEvent<any>> {

    const rb = new RequestBuilder(this.rootUrl, QuestionbankControllerService.UploadResourceToQuestionPath, 'post');
    if (params) {
      rb.path('questionBankId', params.questionBankId, {});
      rb.path('questionId', params.questionId, {});
      rb.query('resourceId', params.resourceId, {});
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      reportProgress: true
    })
    );
  }

  /**
   * Upload resource to question.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadResourceToQuestion$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadResourceToQuestion(params: {

    /**
     * Identifier of questionbank
     */
    questionBankId: number;

    /**
     * Identifier of question
     */
    questionId: number;

    /**
     * Optional identifier of uploadable resource
     */
    resourceId?: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body?: { 'file': Blob }
  }): Observable<HttpEvent<any>> {

    return this.uploadResourceToQuestion$Response(params);
  }

  /**
   * Path part for operation getQuestionResourceByQuestion
   */
  static readonly GetQuestionResourceByQuestionPath = '/questionbanks/{questionBankId}/questions/{questionId}/resources/{resourceId}';

  /**
   * Get the specific resource by question
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuestionResourceByQuestion()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionResourceByQuestion$Response(params: {

    /**
     * Identifier of questionbank
     */
    questionBankId: number;

    /**
     * Identifier of question
     */
    questionId: number;

    /**
     * Identifier of resources
     */
    resourceId: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QuestionbankControllerService.GetQuestionResourceByQuestionPath, 'get');
    if (params) {
      rb.path('questionBankId', params.questionBankId, {});
      rb.path('questionId', params.questionId, {});
      rb.path('resourceId', params.resourceId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Get the specific resource by question
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQuestionResourceByQuestion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionResourceByQuestion(params: {

    /**
     * Identifier of questionbank
     */
    questionBankId: number;

    /**
     * Identifier of question
     */
    questionId: number;

    /**
     * Identifier of resources
     */
    resourceId: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.getQuestionResourceByQuestion$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
