import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { IChoiceAnswerModel } from 'src/app/api-wrapper/models/widget-models/questions/choice-question/choice-anser.model';
import { RealTimeBaseQuestionWidgetForm } from 'src/app/api-wrapper/models/widget-models/questions/question-base-model';

export function iChoiceAnswerFactory(data?: Partial<IChoiceAnswerModel>): FormGroupTyped<IChoiceAnswerModel> {
    return new FormGroupTyped<IChoiceAnswerModel>({
        correct: FormHelper.controlFactoryWithCalculatedValue(data?.correct ?? false),
        explanation: FormHelper.controlFactoryWithCalculatedValue(data?.explanation ?? null),
        id: FormHelper.controlFactoryWithCalculatedValue(data?.id),
        mediaResourceElement: FormHelper.controlFactoryWithCalculatedValue({
            fileItem: data?.mediaResourceElement?.fileItem ?? null,
            elementId: data?.mediaResourceElement?.elementId ?? null,
        }),
        text: FormHelper.controlFactoryWithCalculatedValue(data?.text ?? null),
    });
}

export function realTimeBaseQuestionWidgetFormFactory(additionalFields?: {
    [key: string]: AbstractControl;
}): FormGroupTyped<RealTimeBaseQuestionWidgetForm> {
    return new FormGroupTyped<RealTimeBaseQuestionWidgetForm>({
        answers: new UntypedFormArray([]),
        text: FormHelper.controlFactoryWithCalculatedValue(null),
        introduction: FormHelper.controlFactoryWithCalculatedValue(null),
        pdfResourceElement: FormHelper.controlFactoryWithCalculatedValue(null),
        mediaResourceElement: FormHelper.controlFactoryWithCalculatedValue(null),
        videoResourceElement: FormHelper.controlFactoryWithCalculatedValue(null),
        audioResourceElement: FormHelper.controlFactoryWithCalculatedValue(null),
        hasAnswerMedia: FormHelper.controlFactoryWithCalculatedValue(null),
        hasMedia: FormHelper.controlFactoryWithCalculatedValue(null),
        hasPdf: FormHelper.controlFactoryWithCalculatedValue(null),
        hasVideo: FormHelper.controlFactoryWithCalculatedValue(null),
        hasAudio: FormHelper.controlFactoryWithCalculatedValue(null),
        attempts: FormHelper.controlFactoryWithCalculatedValue(null),
        videoSubtitleResourceElement: new UntypedFormGroup({
            element: FormHelper.controlFactoryWithCalculatedValue({
                fileItem: null,
                elementId: null,
            }),
            srcLang: FormHelper.controlFactoryWithCalculatedValue(null),
            originalFileName: FormHelper.controlFactoryWithCalculatedValue(null),
        }),
        videoTranscript: FormHelper.controlFactoryWithCalculatedValue(null),
        audioDescription: FormHelper.controlFactoryWithCalculatedValue(null),
        ...(additionalFields ?? {}),
    });
}
