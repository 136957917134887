<input type="file" #fileInput [accept]="acceptTypes" (change)="fileChangeListener()" />

<ng-container *ngIf="fileSrc; else emptyBtn">
    <app-custom-video
        #droparea
        [fileSrc]="fileSrc"
        [class.dragover]="dragover"
        [posterValue]="posterValue"
        [orderedScales]="orderedScales"
        [courseId]="courseId"
        [questionBankQuestion]="questionBankQuestion"
        [elementId]="value.elementId"
        [selectedResolution]="selectedResolution"
        [subtitle]="subtitle"
        [loop]="loop"
        [muted]="muted"
        [textWidget]="textWidget"
        [fullSize]="fullSize"
        [autoplay]="autoplay"
        [controlsEnabled]="controlsEnabled"
        (drop)="landFile($event); dragover = false"
        (dragenter)="dragover = true"
        (dragover)="$event.preventDefault(); dragover = true"
        (dragleave)="dragover = false"
    ></app-custom-video>
    <div class="delete-actions" [class.poster]="posterValue">
        <button type="button" (click)="deleteFile()" class="editor-btn height-medium danger border-1 left-icon">
            <span class="nitroicon-delete editor-btn-icon"></span>
            {{ 'VIDEO-WIDGET.FILE-DELETE' | translate }}
        </button>

        <button
            *ngIf="posterValue"
            (click)="onDeletePoster()"
            type="button"
            class="editor-btn height-medium danger border-1 left-icon"
        >
            <span class="nitroicon-delete editor-btn-icon"></span>
            {{ 'VIDEO-WIDGET.PREVIEW-IMAGE-DELETE' | translate }}
        </button>
    </div>
</ng-container>

<ng-template #emptyBtn>
    <div class="dropper" #droparea>
        <button
            data-e2e="video-upload"
            class="empty-btn"
            (click)="fileInput.click()"
            (drop)="landFile($event); droparea.className = 'dropper'"
            (dragenter)="droparea.className = 'dropper dragover'"
            (dragover)="$event.preventDefault()"
            (dragleave)="droparea.className = 'dropper'"
            tabindex="0"
        >
            <span #contentWrapper>
                <ng-content></ng-content>
            </span>

            <div *ngIf="contentWrapper.childNodes.length === 0" class="empty-btn__cover"></div>
        </button>
    </div>
</ng-template>
