import {
    GeneralTextExplanation,
    GeneralVideoExplanation,
    Question,
    ResultWithTextExplanation,
    ResultWithVideoExplanation,
} from '@apiModels';
import { ExplanationResourceType } from '../../explanation-resource.type';
import { IGeneralAudioExplanation } from '../../general-audio-explanation-wrapper.interface';
import { IGeneralVideoExplanation } from '../../general-video-explanation-wrapper.interface';
import { IResultWithAudioExplanation } from '../../result-audio-explanation-wrapper.interface';
import { IResultWithVideoExplanation } from '../../result-video-explanation-wrapper.interface';
import { initialAudioResource, initialVideoResource } from './initial-audio-video-resources';

export const initialGeneralExplanationFormData = {
    explanationText: null,
    audioResource: initialAudioResource,
    videoResource: initialVideoResource,
};

export const initialResultExplanationFormData = {
    explanationCorrectText: null,
    explanationIncorrectText: null,
    partialCorrectText: null,
    correctAudioResource: initialAudioResource,
    incorrectAudioResource: initialAudioResource,
    partialCorrectAudioResource: initialAudioResource,
    correctVideoResource: initialVideoResource,
    incorrectVideoResource: initialVideoResource,
    partialCorrectVideoResource: initialVideoResource,
};

export function InitGeneralExplanationType(question?: Question): ExplanationResourceType | null {
    if (!question?.generalExplanationConfig) {
        return null;
    }

    if (question?.generalExplanationConfig?.explanationType === 'GENERAL_TEXT') {
        return 'TEXT';
    }
    if (question?.generalExplanationConfig?.explanationType === 'GENERAL_VIDEO') {
        return 'VIDEO';
    }
    if (question?.generalExplanationConfig?.explanationType === 'GENERAL_AUDIO') {
        return 'AUDIO';
    }
    return null;
}

export function InitResultExplanationType(question?: Question): ExplanationResourceType | null {
    if (!question?.resultExplanationConfig) {
        return null;
    }

    if (question?.resultExplanationConfig?.explanationType === 'RESULT_WITH_TEXT') {
        return 'TEXT';
    }
    if (question?.resultExplanationConfig?.explanationType === 'RESULT_WITH_VIDEO') {
        return 'VIDEO';
    }
    if (question?.resultExplanationConfig?.explanationType === 'RESULT_WITH_AUDIO') {
        return 'AUDIO';
    }
    return null;
}

export function InitGeneralExplanationConfig(
    question?: Question
): IGeneralAudioExplanation | GeneralTextExplanation | IGeneralVideoExplanation | null {
    if (!question?.generalExplanationConfig) {
        return initialGeneralExplanationFormData;
    }

    if (question?.generalExplanationConfig?.explanationType === 'GENERAL_TEXT') {
        return {
            ...initialGeneralExplanationFormData,
            explanationText: (question?.generalExplanationConfig as GeneralTextExplanation)?.explanationText ?? null,
        };
    }
    if (question?.generalExplanationConfig?.explanationType === 'GENERAL_VIDEO') {
        return {
            ...initialGeneralExplanationFormData,
            videoResource: {
                ...(question?.generalExplanationConfig as IGeneralVideoExplanation)?.videoResource,
                resourceType:
                    (question?.generalExplanationConfig as IGeneralVideoExplanation)?.videoResource?.resourceType ?? '',
                videoResourceElement: {
                    elementId:
                        (question?.generalExplanationConfig as IGeneralVideoExplanation)?.videoResource
                            ?.videoResourceId ?? null,
                    fileItem: null,
                },
                videoSubtitle: {
                    element: {
                        elementId:
                            (question?.generalExplanationConfig as GeneralVideoExplanation)?.videoResource
                                ?.videoSubtitle?.subtitleResourceId ?? null,
                        fileItem: null,
                    },
                    originalFileName:
                        (question?.generalExplanationConfig as IGeneralVideoExplanation)?.videoResource?.videoSubtitle
                            ?.originalFileName ?? null,
                    srcLang:
                        (question?.generalExplanationConfig as IGeneralVideoExplanation)?.videoResource?.videoSubtitle
                            ?.srcLang ?? null,
                },
            },
        };
    }
    if (question?.generalExplanationConfig?.explanationType === 'GENERAL_AUDIO') {
        return {
            ...initialGeneralExplanationFormData,
            audioResource: {
                ...(question?.generalExplanationConfig as IGeneralAudioExplanation)?.audioResource,
                audioResourceElement: {
                    elementId:
                        (question?.generalExplanationConfig as IGeneralAudioExplanation)?.audioResource?.resourceId ??
                        null,
                    fileItem: null,
                },
            },
        };
    }
    return null;
}

export function InitResultExplanationConfig(
    question?: Question
): IResultWithAudioExplanation | ResultWithTextExplanation | IResultWithVideoExplanation | null {
    if (!question?.resultExplanationConfig) {
        return initialResultExplanationFormData;
    }

    if (question?.resultExplanationConfig?.explanationType === 'RESULT_WITH_TEXT') {
        return {
            ...initialResultExplanationFormData,
            explanationCorrectText:
                (question?.resultExplanationConfig as ResultWithTextExplanation)?.explanationCorrectText ?? null,
            explanationIncorrectText:
                (question?.resultExplanationConfig as ResultWithTextExplanation)?.explanationIncorrectText ?? null,
            partialCorrectText:
                (question?.resultExplanationConfig as ResultWithTextExplanation)?.partialCorrectText ?? null,
        };
    }
    if (question?.resultExplanationConfig?.explanationType === 'RESULT_WITH_VIDEO') {
        return {
            ...initialResultExplanationFormData,
            correctVideoResource: {
                ...(question?.resultExplanationConfig as IResultWithVideoExplanation)?.correctVideoResource,
                resourceType:
                    (question?.resultExplanationConfig as IResultWithVideoExplanation)?.correctVideoResource
                        ?.resourceType ?? '',
                videoResourceElement: {
                    elementId:
                        (question?.resultExplanationConfig as IResultWithVideoExplanation)?.correctVideoResource
                            ?.videoResourceId ?? null,
                    fileItem: null,
                },
                videoSubtitle: {
                    element: {
                        elementId:
                            (question?.resultExplanationConfig as ResultWithVideoExplanation)?.correctVideoResource
                                ?.videoSubtitle?.subtitleResourceId ?? null,
                        fileItem: null,
                    },
                    originalFileName:
                        (question?.resultExplanationConfig as IResultWithVideoExplanation)?.correctVideoResource
                            ?.videoSubtitle?.originalFileName ?? null,
                    srcLang:
                        (question?.resultExplanationConfig as IResultWithVideoExplanation)?.correctVideoResource
                            ?.videoSubtitle?.srcLang ?? null,
                },
            },
            incorrectVideoResource: {
                ...(question?.resultExplanationConfig as IResultWithVideoExplanation)?.incorrectVideoResource,
                resourceType:
                    (question?.resultExplanationConfig as IResultWithVideoExplanation)?.incorrectVideoResource
                        ?.resourceType ?? '',
                videoResourceElement: {
                    elementId:
                        (question?.resultExplanationConfig as IResultWithVideoExplanation)?.incorrectVideoResource
                            ?.videoResourceId ?? null,
                    fileItem: null,
                },
                videoSubtitle: {
                    element: {
                        elementId:
                            (question?.resultExplanationConfig as ResultWithVideoExplanation)?.incorrectVideoResource
                                ?.videoSubtitle?.subtitleResourceId ?? null,
                        fileItem: null,
                    },
                    originalFileName:
                        (question?.resultExplanationConfig as IResultWithVideoExplanation)?.incorrectVideoResource
                            ?.videoSubtitle?.originalFileName ?? null,
                    srcLang:
                        (question?.resultExplanationConfig as IResultWithVideoExplanation)?.incorrectVideoResource
                            ?.videoSubtitle?.srcLang ?? null,
                },
            },
            partialCorrectVideoResource: {
                ...(question?.resultExplanationConfig as IResultWithVideoExplanation)?.partialCorrectVideoResource,
                resourceType:
                    (question?.resultExplanationConfig as IResultWithVideoExplanation)?.partialCorrectVideoResource
                        ?.resourceType ?? '',
                videoResourceElement: {
                    elementId:
                        (question?.resultExplanationConfig as IResultWithVideoExplanation)?.partialCorrectVideoResource
                            ?.videoResourceId ?? null,
                    fileItem: null,
                },
                videoSubtitle: {
                    element: {
                        elementId:
                            (question?.resultExplanationConfig as ResultWithVideoExplanation)
                                ?.partialCorrectVideoResource?.videoSubtitle?.subtitleResourceId ?? null,
                        fileItem: null,
                    },
                    originalFileName:
                        (question?.resultExplanationConfig as IResultWithVideoExplanation)?.partialCorrectVideoResource
                            ?.videoSubtitle?.originalFileName ?? null,
                    srcLang:
                        (question?.resultExplanationConfig as IResultWithVideoExplanation)?.partialCorrectVideoResource
                            ?.videoSubtitle?.srcLang ?? null,
                },
            },
        };
    }
    if (question?.resultExplanationConfig?.explanationType === 'RESULT_WITH_AUDIO') {
        return {
            ...initialResultExplanationFormData,
            correctAudioResource: {
                ...(question?.resultExplanationConfig as IResultWithAudioExplanation)?.correctAudioResource,
                audioResourceElement: {
                    elementId:
                        (question?.resultExplanationConfig as IResultWithAudioExplanation)?.correctAudioResource
                            ?.resourceId ?? null,
                    fileItem: null,
                },
            },
            incorrectAudioResource: {
                ...(question?.resultExplanationConfig as IResultWithAudioExplanation)?.incorrectAudioResource,
                audioResourceElement: {
                    elementId:
                        (question?.resultExplanationConfig as IResultWithAudioExplanation)?.incorrectAudioResource
                            ?.resourceId ?? null,
                    fileItem: null,
                },
            },
            partialCorrectAudioResource: {
                ...(question?.resultExplanationConfig as IResultWithAudioExplanation)?.partialCorrectAudioResource,
                audioResourceElement: {
                    elementId:
                        (question?.resultExplanationConfig as IResultWithAudioExplanation)?.partialCorrectAudioResource
                            ?.resourceId ?? null,
                    fileItem: null,
                },
            },
        };
    }
    return null;
}
