<form [formGroup]="form" class="random-question-detail-form">
    <app-random-question-real-time-widget
        class="detail-form__real-time-widget"
        [editorLocation]="'detail'"
        [formControlName]="'realTimeRandomQuestionFormData'"
    ></app-random-question-real-time-widget>
    <!--  Fullfillment -->
    <section class="fulfillment-details">
        <app-collapse-card [title]="'IMAGE-WIDGET.MEASUREMENT-CARD' | translate" [opened]="true">
            <app-radio-group-control
                class="detail-form__group"
                [title]="'QUESTION-WIDGET.COMMON.MEASUREMENT.FULFILLMENT-AVAILABLE-TITLE' | translate"
                [options]="fulfillmentAvailableOptions"
                formControlName="fulfillmentAvailable"
            ></app-radio-group-control>

            <app-radio-group-control
                class="detail-form__group"
                [title]="'FORM-CONTROLS.FULFILLMENT-TITLE' | translate"
                [options]="fulfillmentOptions"
                formControlName="fulfillment"
            ></app-radio-group-control>
        </app-collapse-card>
    </section>
</form>