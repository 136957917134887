/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Group, Question } from '@apiModels';
import { GroupQuestion } from 'src/app/api/models/group-question';

import { TypedResponse } from '../../base-widget-models/typed-widget-response';
import { InitRealTimeQuestionFormData } from '../init-real-time-form';
import {
    BaseQuestionWidgetDataSpec,
    DetailBaseQuestionWidgetForm,
    QuestionBaseWidgetClass,
    RealTimeBaseQuestionWidgetForm,
} from '../question-base-model';

export interface RealTimeGroupQuestionWidgetForm extends RealTimeBaseQuestionWidgetForm {
    groups?: Array<Group>;
    nonGrouped?: Array<string>;
}

export interface DetailGroupQuestionWidgetForm extends DetailBaseQuestionWidgetForm {
    realTimeQuestionWidgetFormData: RealTimeGroupQuestionWidgetForm;
}

export type MixedChoiceQuestionForm = RealTimeGroupQuestionWidgetForm & DetailGroupQuestionWidgetForm;

export interface GroupQuestionWidgetData extends BaseQuestionWidgetDataSpec {
    question: GroupQuestion;
    fulfillment: 'NONE' | 'SHOW' | 'QUESTION_CORRECTLY_ANSWERED' | 'QUESTION_ANSWERED';
}

export class GroupQuestionWidgetModel extends QuestionBaseWidgetClass<
    GroupQuestionWidgetData,
    RealTimeGroupQuestionWidgetForm,
    DetailGroupQuestionWidgetForm
> {
    constructor(data: TypedResponse<GroupQuestionWidgetData>) {
        super(data, null);
    }

    getRealTimeFormData(): RealTimeGroupQuestionWidgetForm {
        const { nonGrouped, groups } = this.data.question;
        return {
            groups: groups ?? [],
            nonGrouped: nonGrouped ?? [],
            ...InitRealTimeQuestionFormData(
                this.data,
                this.fileUploadElement,
                this.mediaUploadElement,
                this.videoUploadElement,
                this.audioUploadElement,
                this.videoSubtitleElement
            ),
        };
    }

    updateAnswers(questionData: Question, answers: never[]): unknown[] {
        return [];
    }
}
